import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import React from 'react';
import { useStatusQuery } from '../../generated/graphql';
import Loading from '../Loading/Loading';
import styles from './Status.module.scss';


const Status = () => {

    const { data, loading } = useStatusQuery()

    if (loading) {
        return <Loading />;
    }

    const status = data?.status

    return (
        <Grid container justify="center" alignItems="center" className={styles.StatusWrapper}>
            <div className={styles.Status}>
                <p data-cy="status" className={classNames({ [styles.Up]: status === 'up' })}>
                    API STATUS: {status?.toUpperCase()}
                </p>
            </div>
        </Grid>
    );
};

export default Status;
