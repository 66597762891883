import { Grid, Zoom } from '@material-ui/core';
import React, { useState } from 'react';
import { useGtXs } from '../../hooks/useGtXs';
import Show from '../Show/Show';
import styles from './EloActivity.module.scss';

interface EloActivityProps {
    activity: any
}

export default function EloActivity(props: EloActivityProps) {

    const { activity } = props

    const [checkedMore, setCheckedMore] = useState(false)
    const [checkedEnroll, setCheckedEnroll] = useState(false)
    const gtXs = useGtXs()

    const handleCheck = (box: "knowMore" | "enroll") => {
        if (box === "knowMore") {
            setCheckedMore(!checkedMore)
            if (!checkedMore) setCheckedEnroll(false)
        } else {
            setCheckedEnroll(!checkedEnroll)
            if (!checkedEnroll) setCheckedMore(false)
        }
    }


    return (
        <div>
            <Grid container className={styles.Activity} justify="space-between">
                <Grid item xs={12} sm={4}>
                    <Show on={!gtXs}>
                        <h3>{activity.name}</h3>
                    </Show>
                    <img src={activity.cover} alt="" />

                </Grid>
                <Grid container item xs={12} sm={7} alignContent="space-between" className={styles.Information}>

                    <Show on={gtXs}>
                        <h3>{activity.name}</h3>
                    </Show>

                    <table>
                        <tr>
                            <td>Professores</td>
                            <td>{activity.teachers}</td>
                        </tr>
                        <tr>
                            <td>Horário</td>
                            <td dangerouslySetInnerHTML={{ __html: activity.schedule! }}></td>
                        </tr>
                        <tr>
                            <td>Local</td>
                            <td>{activity.location}</td>
                        </tr>
                        <tr>
                            <td>Público</td>
                            <td dangerouslySetInnerHTML={{ __html: activity.audience! }}></td>
                        </tr>
                        <tr>
                            <td>Convidados</td>
                            <td>{activity.guests}</td>
                        </tr>
                    </table>

                    <div className={styles.Actions}>
                        <span onClick={() => handleCheck("knowMore")}>Saber Mais &nbsp;&nbsp; &gt; </span>
                        <Show on={activity.openRegistrations}>
                            <span onClick={() => handleCheck("enroll")}>Inscrições &nbsp;&nbsp; &gt; </span>
                        </Show>
                        <Show on={!activity.openRegistrations}>
                            <span className={styles.SoldOut}>Esgotado</span>
                        </Show>
                    </div>


                </Grid>
            </Grid>

            <Grid container justify="space-between">
                <Grid item xs={12} sm={4}>
                </Grid>
                <Grid container item xs={12} sm={7}>
                    <div className={styles.Zoom}>
                        <Show on={checkedMore}>
                            <Zoom in={checkedMore}>
                                <div>
                                    De outubro a junho, este espaço irá receber todas as semanas sessões de teatro
                                    com direção artística de Pedro Lamares, e a sua equipa.
                                    Os exercícios, construções dramatúrgicas e apresentações, que culminarão com
                                    uma subida ao grande palco do Coliseu em junho de 2024.
                                    O convite abre-se aos amantes do teatro e das artes de palco, mas é também
                                    extensível a curiosos, com ou sem experiência nesta arte, cuja vontade os faça
                                    abraçar um projeto de 9 meses, integrando uma nova e grande família neste
                                    projeto artístico chamado ELO.
				</div>
                            </Zoom>
                        </Show>
                        <Show on={checkedEnroll}>
                            <Zoom in={checkedEnroll}>
                                <div>
                                    Envie email para educativo@coliseu.pt com os seguintes dados: Nome, Data de nascimento, NIF, Necessidades especiais, Telefone, Email.
				</div>
                            </Zoom>
                        </Show>
                    </div>
                </Grid>
            </Grid>

        </div>
    )
}
