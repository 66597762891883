import React from 'react'
import { Link } from '../Link/Link'
import Typography from '../Typography/Typography'
import styles from './OutlineButton.module.scss'

interface OutlineButtonProps {
    route: string
    name: string
}

export default function OutlineButton(props: OutlineButtonProps) {
    const { route, name } = props
    return (
        <div className={styles.Button}>
            <Link route={route}>
                <Typography variant="menu">
                    {name} &gt;
		</Typography>
            </Link>
        </div>
    )
}
