import { Grid } from '@material-ui/core';
import React from 'react';
import BlackButton from '../BlackButton/BlackButton';
import EducationalHero from '../EducationalHero/EducationalHero';
import GoBackToSchool from '../GoBackToSchool/GoBackToSchool';
import { EducationalCategory } from '../SchoolsView/Activities';
import styles from './TeacherTraining.module.scss';

interface TeacherTrainingProps {
    category: EducationalCategory
}

export default function TeacherTraining(props: TeacherTrainingProps) {
    const { category } = props
    return (
        <div>
            <GoBackToSchool />
            <EducationalHero category={category.name} />
            <Grid container justify="center" className={styles.Wrapper}>
                <main>
                    <div className={styles.Title}>ACD CREDITADA</div>
                    <div className={styles.Date}>Em breve</div>
                    <div className={styles.Message}>
                        <p>Siga a newsletter do Coliseu para informações em primeira mão</p>
                    </div>
                    <div>
                        <BlackButton text="Subscrever Newsletter" to="/newsletter/subscrever" />
                    </div>
                </main>
            </Grid>
        </div>
    )
}
