import { Grid } from '@material-ui/core';
import React, { ReactNode } from 'react';
import styles from './StandardPage.module.scss';

interface StandardPageProps {
    background?: string
    children?: ReactNode
}

export default function StandardPage(props: StandardPageProps) {

    const { children, background = '#e5e5e5' } = props

    return (
        <Grid container justify="center" style={{ backgroundColor: background }}>
            <div className={styles.PageWrapper}>
                {children}
            </div>
        </Grid>
    )
}
