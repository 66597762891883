import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import getSectionSubsections from '../Sitemap/Helpers/getSectionSubsections';
import Submenu from '../Submenu/Submenu';

interface FriendsViewProps extends RouteComponentProps<{}> { }

export default function FriendsView(props: FriendsViewProps) {
    const { match } = props

    const subsections = getSectionSubsections(7)
    const subsection = subsections.find(subsection => subsection.route === match.path)
    const component = subsection ? subsection.component : null

    return (
        <div>
            <Submenu items={subsections} path={match.path} />
            {component}
        </div>
    )
}
