import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import React from 'react';
import { Helmet } from "react-helmet";
import { Config } from '../../../services/Config';
import CircusRow from '../CircusRow/CircusRow';
import artists from './Artists.json';
import styles from './Circus2019View.module.scss';
import photo1 from '../../../assets/images/circus/2019/photos/coliseu_porto_ageas_circo_2019_01.jpg';
import photo2 from '../../../assets/images/circus/2019/photos/coliseu_porto_ageas_circo_2019_02.jpg';
import photo3 from '../../../assets/images/circus/2019/photos/coliseu_porto_ageas_circo_2019_03.jpg';
import photo4 from '../../../assets/images/circus/2019/photos/coliseu_porto_ageas_circo_2019_04.jpg';
import photo5 from '../../../assets/images/circus/2019/photos/coliseu_porto_ageas_circo_2019_05.jpg';
import photo6 from '../../../assets/images/circus/2019/photos/coliseu_porto_ageas_circo_2019_06.jpg';
import photo7 from '../../../assets/images/circus/2019/photos/coliseu_porto_ageas_circo_2019_07.jpg';
import photo8 from '../../../assets/images/circus/2019/photos/coliseu_porto_ageas_circo_2019_08.jpg';
import photo9 from '../../../assets/images/circus/2019/photos/coliseu_porto_ageas_circo_2019_09.jpg';
import photo10 from '../../../assets/images/circus/2019/photos/coliseu_porto_ageas_circo_2019_10.jpg';
import photo11 from '../../../assets/images/circus/2019/photos/coliseu_porto_ageas_circo_2019_11.jpg';
import photo12 from '../../../assets/images/circus/2019/photos/coliseu_porto_ageas_circo_2019_12.jpg';
import photo13 from '../../../assets/images/circus/2019/photos/coliseu_porto_ageas_circo_2019_13.jpg';
import photo14 from '../../../assets/images/circus/2019/photos/coliseu_porto_ageas_circo_2019_14.jpg';
import CircusPhotoGallery from '../CircusPhotoGallery/CircusPhotoGallery';

const Circus2019View = () => {

    const photos = [
        photo1,
        photo2,
        photo3,
        photo4,
        photo5,
        photo6,
        photo7,
        photo8,
        photo9,
        photo10,
        photo11,
        photo12,
        photo13,
        photo14
    ];

    const buyLink = "https:www.coliseu.pt/evento/20191213-circo-coliseu-porto-2019/";
    return (
        <>
            <Helmet>
                <meta property="og:site_name" content="Coliseu Porto Ageas" />
                <meta property="og:url" content={`${Config.APP}circo-2019`} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Coliseu Porto Ageas" />
                <meta property="og:description" content="Alguns dos melhores artistas do mundo mostram o seu talento na pista entre 13 de dezembro e 1 de janeiro. As oito troupes selecionadas para a Companhia deste ano chegam de países como a Rússia, Canadá, Tajiquistão, Finlândia, Ucrânia e também de Portugal." />
                <meta property="og:image" content={`${Config.APP}assets/images/circus/2019/circo2019_social.jpg}`} />
                <meta property="og:image:width" content="658" />
                <meta property="og:image:height" content="659" />
            </Helmet>

            <div className={styles.Circus2019}>
                <div className={styles.Hero}>
                </div>
                <div className={classNames([styles.CircusRow, styles.Color0])}>
                    <h1>A alegria continua</h1>

                    <h2>O Coliseu Porto Ageas apresenta o seu Circo de Natal,
			uma verdadeira festa da felicidade e do fantástico, e sem
			animais em pista desde 2015!</h2>

                    <Button className={styles.BuyLink} href={buyLink}>
                        Compre já
		    </Button>

                    <h2>Alguns dos melhores artistas do mundo mostram o seu
			talento na pista entre 13 de dezembro e 1 de janeiro. As oito
			troupes selecionadas para a Companhia deste ano
			chegam de países como a Rússia, Canadá, Tajiquistão,
			Finlândia, Ucrânia e também de Portugal.</h2>

                    <h2>Completamente programado pelo Coliseu, o cartaz inclui
			altos voos, trapézio triplo, um virtuoso da corda-bamba e
			muito mais. Todos prontos a mostrar, diante dos olhos do
			público de todas as idades, o que as artes circenses têm
			de mais fantástico e autêntico. Um espetáculo capaz de
			reinterpretar os clássicos da tradição circense aliando-os
			às disciplinas mais contemporâneas, resultando em
			números de extraordinária beleza. Uma tradição que
			o Coliseu mantém viva desde 1941, e que todos os anos atrai
			100 mil pessoas.</h2>
                </div>

                {artists.map(artist => <CircusRow key={Math.random()} {...artist} />)}
                <CircusPhotoGallery photos={photos} year={2019} />
            </div>
        </>
    );


};

export default Circus2019View;
