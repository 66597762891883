import Grid from '@material-ui/core/Grid';
import React from 'react';
import circo2016logo from '../../../assets/images/circus/2016/circo_2016_logo.png';
import photo8 from '../../../assets/images/circus/2016/photos/nB1FBBqfLx.jpg';
import photo15 from '../../../assets/images/circus/2016/photos/nBJMqvczUx.jpg';
import photo6 from '../../../assets/images/circus/2016/photos/nBypSQd6Ux.jpg';
import photo16 from '../../../assets/images/circus/2016/photos/nBywdu9GUx.jpg';
import photo9 from '../../../assets/images/circus/2016/photos/nHJbYr9zUl.jpg';
import photo3 from '../../../assets/images/circus/2016/photos/nHJFhX9M8e.jpg';
import photo11 from '../../../assets/images/circus/2016/photos/nHy0bU9zUl.jpg';
import photo17 from '../../../assets/images/circus/2016/photos/nHy5Mu5GLe.jpg';
import photo2 from '../../../assets/images/circus/2016/photos/nHyVHQ5MUg.jpg';
import photo5 from '../../../assets/images/circus/2016/photos/nr1h7E5GIx.jpg';
import photo18 from '../../../assets/images/circus/2016/photos/nrJ1SucfIl.jpg';
import photo12 from '../../../assets/images/circus/2016/photos/nrJcX8qfUg.jpg';
import photo1 from '../../../assets/images/circus/2016/photos/nrJKe79M8g.jpg';
import photo10 from '../../../assets/images/circus/2016/photos/nrkCRScz8g.jpg';
import photo4 from '../../../assets/images/circus/2016/photos/nS1KO45GLg.jpg';
import photo13 from '../../../assets/images/circus/2016/photos/nSJu7wqfLl.jpg';
import photo7 from '../../../assets/images/circus/2016/photos/nSk38NcGLx.jpg';
import photo14 from '../../../assets/images/circus/2016/photos/nSyWuw9GLg.jpg';
import CircusPhotoGallery from '../CircusPhotoGallery/CircusPhotoGallery';
import CircusRow from '../CircusRow/CircusRow';
import artists from './Artists.json';
import styles from './Circus2016View.module.scss';

const Circus2016View = () => {

    const photos = [
        photo1,
        photo2,
        photo3,
        photo4,
        photo5,
        photo6,
        photo7,
        photo8,
        photo9,
        photo10,
        photo11,
        photo12,
        photo13,
        photo14,
        photo15,
        photo16,
        photo17,
        photo18,

    ];

    return (
        <div className={styles.Circus2016}>
            <Grid container className={styles.Hero} justify="center" alignItems="center">
                <img src={circo2016logo} alt="" />
            </Grid>

            <Grid container direction="column" justify="center" alignItems="center" className={styles.Intro}>


                <h1>Senhoras e senhores, bem-vindos ao prestigiado  Circo Coliseu Porto.</h1>

                <h3>
                    O Coliseu Porto convida a assistir ao melhor e mais completo espetáculo de circo que até hoje apresentámos. Este ano a Companhia será criada nos bastidores do Coliseu, numa seleção integralmente programada pela nossa equipa. Seguindo a qualidade e prestígio dos grandes circos internacionais, o Coliseu Porto vai acolher os melhores números atualmente em tournée mundial, com artistas contemporâneos que reinterpretam os clássicos da tradição circense.
            </h3>

                <p>
                    Trapezistas, acrobatas, palhaços e ilusionistas de referência internacional vão estar reunidos na nossa cidade, num conjunto de elevada qualidade artística e prescindindo de números com animais.
            </p>

            </Grid>
            {artists.map(artist => <CircusRow key={Math.random()} {...artist} />)}

            <CircusPhotoGallery photos={photos} year={2016} />
        </div>
    );
};

export default Circus2016View;
