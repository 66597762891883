import { Grid } from '@material-ui/core';
import React from 'react';
import mainBanner from '../../assets/images/educational/schools/banner_programa_escolas.webp';
import areasDesktop from '../../assets/images/educational/schools/icon_areas_desktop.png';
import areasMobile from '../../assets/images/educational/schools/icon_areas_mobile.png';
import { useGtXs } from '../../hooks/useGtXs';
import { Config } from '../../services/Config';
import ActivityCarousel from '../ActivityCarousel/ActivityCarousel';
import EducationalActivitySearch from '../EducationalActivitySearch/EducationalActivitySearch';
import styles from './SchoolsView.module.scss';

export default function SchoolsView() {

    const gtXs = useGtXs()
    const iconAreas = gtXs ? areasDesktop : areasMobile
    const url = Config.SCHOOLS_PROGRAM

    return (
        <div>
            <ActivityCarousel />
            <Grid container justify="center" className={styles.Wrapper}>
                <main>
                    <Grid container spacing={2} className={styles.Introduction}>
                        <Grid item xs={12} sm={7} className={styles.Banner}>
                            <div>
                                <img src={mainBanner} alt="" />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={5} className={styles.Content}>
                            <Grid>
                                <p>Após o sucesso das primeiras Oficinas de Verão, que trouxeram mais de 60 crianças aos diferentes espaços do Coliseu,
					o novo Serviço Educativo do Coliseu abre agora as portas às escolas, com propostas desde o pré-escolar até ao ensino técnico, superior e sénior.</p>

                                <p>Pretendemos criar, com todos, laços vincados e duradouros, partilhar o nosso sentido de missão e
					proporcionar lugar e tempo para a educação e fruição artísticas.</p>

                                <p><b>SEJA BEM-VINDO AO COLISEU DAS ESCOLAS.</b></p>
                                <p>Consulte o programa completo aqui:</p>

                                <a href={url} target="_blank" rel="noopener noreferrer">
                                    <div className={styles.Download}>
                                        Programa Escolas 2023/24
				    </div>
                                </a>

                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={styles.ActivityAreas}>
                        <img src={iconAreas} alt="" />
                    </Grid>

                    <EducationalActivitySearch />
                </main>
            </Grid>
        </div >
    )
}
