import Grid from '@material-ui/core/Grid';
import React from 'react';
import circo2015logo from '../../../assets/images/circus/2015/circo_2015_logo.png';
import photo5 from '../../../assets/images/circus/2015/photos/nB1By5W6Ux.jpg';
import photo10 from '../../../assets/images/circus/2015/photos/nB1Dk3kVUl.jpg';
import photo2 from '../../../assets/images/circus/2015/photos/nB1qqubpUg.jpg';
import photo4 from '../../../assets/images/circus/2015/photos/nBJJO5JEUx.jpg';
import photo14 from '../../../assets/images/circus/2015/photos/nBklop14Il.jpg';
import photo15 from '../../../assets/images/circus/2015/photos/nBkPoY1NIl.jpg';
import photo11 from '../../../assets/images/circus/2015/photos/nByMnh148e.jpg';
import photo3 from '../../../assets/images/circus/2015/photos/nHJVduZpUl.jpg';
import photo13 from '../../../assets/images/circus/2015/photos/nHysOTkN8e.jpg';
import photo6 from '../../../assets/images/circus/2015/photos/nr1lxKs2Wg.jpg';
import photo1 from '../../../assets/images/circus/2015/photos/nrJd2ub6Ix.jpg';
import photo12 from '../../../assets/images/circus/2015/photos/nrktZp14Lx.jpg';
import photo7 from '../../../assets/images/circus/2015/photos/nryFtcJ48l.jpg';
import photo9 from '../../../assets/images/circus/2015/photos/nrysToJVUe.jpg';
import photo8 from '../../../assets/images/circus/2015/photos/nSkvej1E8x.jpg';
import CircusPhotoGallery from '../CircusPhotoGallery/CircusPhotoGallery';
import CircusRow from '../CircusRow/CircusRow';
import artists from './Artists.json';
import styles from './Circus2015View.module.scss';

const Circus2015View = () => {

    const photos = [
        photo1,
        photo2,
        photo3,
        photo4,
        photo5,
        photo6,
        photo7,
        photo8,
        photo9,
        photo10,
        photo11,
        photo12,
        photo13,
        photo14,
        photo15
    ];

    return (
        <div className={styles.Circus2015}>
            <Grid container className={styles.Hero} justify="center" alignItems="center">
                <img src={circo2015logo} alt="" />
            </Grid>

            <Grid container direction="column" justify="center" alignItems="center" className={styles.Intro}>

                <h1>O Circo do Coliseu Porto está de volta à cidade.</h1>

                <h3>No mês de Dezembro, a caravana do circo chega ao Coliseu Porto para mais uma edição do nosso Circo de Natal. Sem números com animais, a pista vai encher-se com a alegria contagiante dos palhaços e a magia das trupes de acrobatas.
              Este ano, todos os números foram programados pelo Coliseu Porto, numa selecção clássica e eclética que inclui escolhas como a trupe de equilibristas do Circo Nacional de Xangai, o triplo salto mortal dos trapezistas The Flying Aces, Nicole Burgio, acrobata do Cirque du Soleil, e muitos mais artistas.</h3>

                <p>E para a festa ser completa, venha celebrar connosco a magia do Natal.
              Saiba tudo sobre o novo Circo do Coliseu Porto, que já vem a caminho.</p>

            </Grid>

            {artists.map(artist => <CircusRow key={Math.random()} {...artist} />)}

            <CircusPhotoGallery photos={photos} year={2015} />
        </div>
    );

};


export default Circus2015View;
