import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Helmet } from "react-helmet";

import photo1 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_01.jpg';
import photo2 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_02.jpg';
import photo3 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_03.jpg';
import photo4 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_04.jpg';
import photo5 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_05.jpg';
import photo6 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_06.jpg';
import photo7 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_07.jpg';
import photo8 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_08.jpg';
import photo9 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_09.jpg';
import photo10 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_10.jpg';
import photo11 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_11.jpg';
import photo12 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_12.jpg';
import photo13 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_13.jpg';
import photo14 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_14.jpg';
import photo15 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_15.jpg';
import photo16 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_16.jpg';
import photo17 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_17.jpg';
import photo18 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_18.jpg';
import photo19 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_19.jpg';
import photo20 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_20.jpg';
import photo21 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_21.jpg';
import photo22 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_22.jpg';
import photo23 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_23.jpg';
import photo24 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_24.jpg';
import photo25 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_25.jpg';
import photo26 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_26.jpg';
import photo27 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_27.jpg';
import photo28 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_28.jpg';
import photo29 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_29.jpg';
import photo30 from '../../../assets/images/circus/2021/photos/coliseu_porto_ageas_circo_2021_30.jpg';
import { Config } from '../../../services/Config';
import CircusRow from '../CircusRow/CircusRow';
import CircusStar from '../CircusStar/CircusStar';
import CircusPhotoGallery from '../CircusPhotoGallery/CircusPhotoGallery';
import artists from './Artists.json';
import styles from './Circus2021View.module.scss';
import social from '../../../assets/images/circus/2021/circo2021_social.jpg'

const Circus2021View = () => {

    const photos = [
        photo1,
        photo2,
        photo3,
        photo4,
        photo5,
        photo6,
        photo7,
        photo8,
        photo9,
        photo10,
        photo11,
        photo12,
        photo13,
        photo14,
        photo15,
        photo16,
        photo17,
        photo18,
        photo19,
        photo20,
        photo21,
        photo22,
        photo23,
        photo24,
        photo25,
        photo26,
        photo27,
        photo28,
        photo29,
        photo30,
    ];


    const buyLink = "https://ticketline.sapo.pt/evento/circo-coliseu-porto-ageas2021-59824";
    return (
        <>
            <Helmet>
                <meta property="og:site_name" content="Coliseu Porto Ageas" />
                <meta property="og:url" content={`${Config.APP}circo-2021`} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Coliseu Porto Ageas" />
                <meta property="og:description" content="Novo desde 1941 - 80 anos de magia do Circo de Natal do Coliseu Porto Ageas." />
                <meta property="og:image" content={"https://www.coliseu.pt" + social} />
                <meta property="og:image:width" content="1920" />
                <meta property="og:image:height" content="1558" />
            </Helmet>

            <div className={styles.Circus2021}>
                <div className={styles.Hero} />

                <Grid container direction="column" className={styles.Intro} justify="center" alignItems="center">
                    <h1>Novo desde 1941</h1>
                    <h2>
                        80 anos de magia do Circo de Natal<br /> do Coliseu Porto Ageas
		    </h2>
                    <h2>
                        Acrobacia, humor, malabarismo, trapézio, equilibrismo, dança, arco e bola aérea, roda alemã, ilusionismo e um globo da morte, música original e uma banda sci-fi. Esta é a proposta do Circo de Natal do Coliseu Porto Ageas que estreia a 10 de dezembro e, até 2 de janeiro, garante magia e diversão intergalática na Baixa portuense.
		    </h2>

                    <Button className={styles.BuyLink} href={buyLink}>
                        Compre já
		    </Button>

                    <div style={{ marginTop: 200, marginBottom: 20 }}>
                        <CircusStar />
                    </div>

                    <h2>

                        Nesta especialíssima edição de aniversário, quando completa 80 voltas ao sol, o Coliseu aposta num conceito de circo mais arrojado, proporcionando uma experiência musical, visual e temática de grande envolvimento e impacto junto do seu público. Contando sempre com números de técnicas circenses que aliam práticas do circo tradicional com a estética do circo contemporâneo, o Circo de Natal 2021 abraça a sala com a música original de Rui Lima e Sérgio Martins (encomendada e composta para cada número), tocada ao vivo por um ensemble dirigido por Suse Ribeiro, num registo funk-electrónico-pop-rock sci-fi que nos transportará por ambientes futuristas e intergaláticos.
		    </h2>

                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                        <CircusStar />
                    </div>

                    <h2>
                        As diversas atuações terão em comum uma paleta cromática presente nos vídeos desenvolvidos pela ESMAD-IPP, que apresentam e expandem os 15 números de circo, nos figurinos desenhados por Cláudia Ribeiro a partir da inspiração de seres em planetas imaginados, e na cenografia e nos adereços com a participação da Escola Artística Soares dos Reis. Espera-se uma explosão de luz, cor e som em torno da possibilidade de exploração de novos mundos – sem ser preciso construir um foguetão!
                        A cada atuação, o público é convidado a visitar um novo planeta e a usufruir de diferentes sensações, estando sempre presente a forma circular: em números aéreos (acrobacias com arco e bola de cristal, voos rasantes acima das cabeças do público), na pista (roda alemã, malabarismo, clown), no palco (globo radical, um número com motorizadas), e em lugares inesperados da sala (funambulismo, grandes ilusões) que farão as cabeças mover-se em várias direções.
		    </h2>

                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                        <CircusStar />
                    </div>

                    <h2>

                        Assim, a partir de 10 de dezembro, o público é convidado a entrar numa viagem espacial, diferente de tudo o que já foi feito, na maior pista de circo (em sala) da Europa. Concebida ao longo de vários meses, por mais de 60 pessoas, a 80ª edição vai encantar e divertir os muitos milhares de pessoas que já não passam sem o Circo de Natal do Coliseu Porto Ageas.
                        Até 2 de janeiro de 2022, há sessões diárias (exceto segundas e terças-feiras) em vários horários. O preço dos bilhetes varia entre os 8€ e os 18€ e aplicam-se descontos.
		    </h2>

                    <Button className={styles.BuyLink} href={buyLink}>
                        Compre já
		    </Button>

                </Grid>


                {artists.map(artist => <CircusRow key={Math.random()} {...artist} />)}

                <div className={styles.LastRow}>
                    <h1>Banda sci-fi</h1>
                    <p>Luís Moreira <span>FILIPE MOREIRA</span> voz</p>
                    <p>Rui Sérgio Rodrigues <span>SAULO GIOVANNINI</span> percussão e sintetizadores</p>
                    <p>Paulo Gravato <span>GABRIEL NEVES</span> saxofones e flauta</p>
                    <p>Daniel Dias <span>ALEXANDRE BAPTISTA</span> trombone</p>
                    <p>Mariana Vieira <span>JOANA DIAS</span> clarinete</p>
                    <p>Gonçalo Ribeiro <span>JOÃO PEDRO ALMEIDA</span> bateria</p>
                    <p>Süse Ribeiro live electronics</p>
                    <CircusStar />
                </div>


                <CircusPhotoGallery photos={photos} year={2021} />

            </div>
        </>
    );


};

export default Circus2021View;
