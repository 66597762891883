import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useHistory } from 'react-router';
import { useGtXs } from '../../hooks/useGtXs';
import { slides } from './slides';
import styles from './ActivityCarousel.module.scss'

export default function ActivityCarousel() {

    const gtXs = useGtXs()
    const history = useHistory()

    const percentage = gtXs ? 34 : 66;

    const handleClick = (slide: any) => {
        history.push(slide.url)
    }

    return (
        <div className={styles.ActivityCarousel}>
            <Carousel showThumbs={false} showArrows={true} centerMode centerSlidePercentage={percentage} swipeable autoPlay infiniteLoop dynamicHeight showStatus={false}>
                {slides.map(slide => (
                    <div key={slide.id} className={styles.Slide} onClick={() => handleClick(slide)}>
                        <img src={slide.src} alt={slide.alt} />
                    </div>
                ))}
            </Carousel>
        </div>
    )
}
