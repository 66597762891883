import React from 'react'
import { PostSortFields, SortDirection, usePostsQuery } from '../../generated/graphql'
import { useGtXs } from '../../hooks/useGtXs'
import { mediaUrl } from '../../services/Utils'
import { abbrevDate } from '../../utils/dates'
import HomeNewsDesktop from '../HomeNewsDesktop/HomeNewsDesktop'
import HomeNewsMobile from '../HomeNewsMobile/HomeNewsMobile'
import Loading from '../Loading/Loading'

export interface HomeNewsContent {
    id: number
    date: string
    name: string
    image: string
    route: string
}

export interface HomeNewsAction {
    name: string
    route: string
    linkName: string
}

export default function HomeNews() {

    const gtXs = useGtXs()

    const { data, loading } = usePostsQuery({
        variables: {
            filter: { state: { eq: "PUBLISHED" } },
            paging: { limit: 2 },
            sorting: [{ field: PostSortFields.Id, direction: SortDirection.Desc }]
        }
    })

    if (loading) {
        return <Loading />
    }

    const content = data?.posts?.nodes.map(post => {
        return {
            id: +post.id,
            date: abbrevDate(post.publishedAt),
            name: post.name,
            image: mediaUrl(post.cover),
            route: `/noticia/${post.slug}`
        }
    })

    const action = {
        name: "Notícias",
        route: "/arquivo-noticias",
        linkName: "Notícias"
    }


    if (gtXs) {
        return <HomeNewsDesktop content={content || []} action={action} />
    }
    return <HomeNewsMobile content={content || []} action={action} />

}
