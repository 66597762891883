import Grid from '@material-ui/core/Grid';
import React from 'react';
import { PostSortFields, SortDirection, usePostsQuery } from '../../generated/graphql';
import LoadingView from '../LoadingView/LoadingView';
import PostPreview from '../PostPreview/PostPreview';
import Typography from '../Typography/Typography';
import styles from './NewsView.module.scss';

const PostItem = (props: any) => (
    <Grid item xs={12} sm={6} lg={4}>
        <PostPreview {...props} />
    </Grid>
);

export default function NewsView() {
    const { data, loading } = usePostsQuery({
        variables: {
            filter: { state: { eq: "PUBLISHED" } },
            paging: { limit: 999 },
            sorting: [{ field: PostSortFields.Id, direction: SortDirection.Desc }]
        }
    })

    if (loading) {
        return <LoadingView />
    }

    const results = data?.posts?.nodes

    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <main>
                <Typography variant="h1colisseum">
                    Arquivo Notícias
		</Typography>

                <Grid container direction="row" alignItems="stretch">
                    {
                        results?.map(post => <PostItem key={post.id} post={post} />)
                    }
                </Grid>
            </main>
        </Grid>
    )
}
