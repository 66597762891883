import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui//icons';
import { Grid, Zoom } from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import logos from "../../assets/images/educational/painting/painting_studio_logos.webp";
import { useGtXs } from '../../hooks/useGtXs';
import Paragraph from '../Paragraph/Paragraph';
import Show from '../Show/Show';
import Typography from '../Typography/Typography';
import styles from './PaintingStudioView.module.scss';

interface FrequentQuestion {
    query: string
    answer: string
}

const questions: FrequentQuestion[] = [
    {
        "query": "Estou a iniciar-me na área da pintura. Conseguirei ter apoio personalizado?",
        "answer": "Cada participante terá o seu próprio proveito e caminho durante o atelier, com base nas suas capacidades e intenções. O apoio será personalizado sempre que possível e necessário, podendo dirigir-se à professora sempre que precisar para obter ajuda. Garantimos apoio individual ao longo de todas as aulas, estando previstos alguns períodos extra de apoio individual se necessário."
    },
    {
        "query": "Já pinto há bastante tempo e tenho experiência em retratos. Este atelier poderá ainda assim ser útil para mim?",
        "answer": "Pessoas com experiência são bem vindas! Iremos dar lugar a uma partilha de conhecimentos dinâmica pelo que a sua presença poderá enriquecer a experiência de todos. Este será um lugar para pensar e criar, que culmina com uma pequena exposição de retratos. Mais do que a qualidade da técnica, iremos incidir nas particularidades que trazem emoção ao retrato e, nesse âmbito, cada imagem é sempre um território novo."
    },
    {
        "query": "Sendo o Coliseu Porto Ageas uma sala de espetáculos, porquê um atelier de pintura nesse lugar?",
        "answer": "Desde a sua génese que o Coliseu apoia todas as artes, entre as quais as artes plásticas. Ainda antes de se edificar como o Coliseu que todos conhecem (1941), neste lugar, no antigo Jardim Passos Manuel, já se apoiavam exposições e criações de jovens artistas, desde a pintura, à dança, cinema, música, etc. Na componente das artes plásticas destaca-se a exposição de 1916, no Salão do Jardim Passos Manuel, onde Amadeo Souza Cardoso teve a sua primeira exposição a solo, com 114 obras, com o título 'abstraccionismo'. A existência de um Serviço Educativo no Coliseu, que teve início em 2023, traz a vontade de honrar esse legado, adicionando a todas as artes que o Coliseu já recebe, a pintura e as artes plásticas. Será justamente no Salão Jardim, sala cujo nome homenageia esta história, que ocorrerão as primeiras aulas de pintura do Serviço Educativo Coliseu."
    },
    {
        "query": "Quem orienta o Atelier?",
        "answer": "O Atelier de Pintura - Retrato é orientado pela professora e artista Filipa Godinho. Contará ainda com o apoio de um elemento da equipa do Serviço Educativo Coliseu. Filipa Godinho é uma artista plástica especializada em pintura de retratos, tendo a sua obra presente em várias coleções nacionais e internacionais. É professora há mais de 20 anos, desde o ensino básico ao ensino superior, público e privado, ensinando práticas de pintura e desenho. Apesar do seu reconhecimento estar mais relacionado com os retratos a óleo, tem um largo leque de retratos a acrílico, a aguarela e a tinta da china. Ainda na representação humana trabalha também em escultura, nomeadamente em cerâmica e bronze."
    },
    {
        "query": "Quero levar os meus materiais. O que tenho de levar?",
        "answer": "As primeiras aulas serão de trabalhos sobre papel, pelo que deverá levar algumas folhas a3 e a2, ora para desenho (papel mais fino) como para pintura a acrílico (mixed media). Deverá levar lápis para desenhar (os que preferir), borracha, carvão, tinta acrílica, recipiente para colocar a água, pincéis, paleta. Deverá ainda usar avental ou outra proteção para a roupa. Dois panos para limpeza de mãos e de pincéis são igualmente úteis. Caso pretenda utilizar outros materiais são benvindos os pastéis secos, pastéis de óleo e aguarelas. Pintura a óleo também será bem vinda desde que com terebentina sem odor. Deverá trazer os seus próprios pincéis. Na fase final do Atelier iremos pintar sobre tela, pelo que deverá adquirir uma tela de 50 x 70 cm. Caso pretenda trazer outros materiais consulte a professora para aconselhamento. Terá acompanhamento técnico em qualquer das técnicas que pretenda usar."
    },
    {
        "query": "Optei pela inscrição com materiais incluídos. Ainda assim devo levar algum material?",
        "answer": "Com esta modalidade de inscrição não precisa de se preocupar com os materiais. O Serviço Educativo Coliseu irá partilhar consigo o que necessitar. Estes materiais serão apenas cedidos para uso durante as aulas, na sala de aula, pelo que não os poderá levar para casa. Caso pretenda dar continuidade aos trabalhos em sua casa deverá, nesse contexto, usar os seus materiais. O trabalho em casa é facultativo. Deverá levar uma proteção para a roupa, como um avental ou bata, e um ou dois panos para limpeza das mãos e pincéis. Poderá levar ainda alguns lápis e borrachas para uso pessoal, bem como qualquer outro material de sua preferência. Emprestaremos cavalete, prancheta A2, e fornecemos carvão, tinta acrílica, pincéis, papel de desenho A2 e papel mixed media para pintura também A2, aguarelas, copos de água para pincéis, uma tela de 50x70cm."
    },
    {
        "query": "Há materiais proibidos?",
        "answer": "Sim. Apelamos ao bom senso de todos no uso dos materiais. O Salão Jardim é uma sala multiusos no Coliseu Porto Ageas, com soalho em madeira, no qual ocorrem muitos eventos, desde aulas de teatro e dança até oficinas com crianças, somando ainda espetáculos de vários tipos. Para mantermos o chão limpo e preservarmos o valor da sala, todos os materiais usados têm de ser laváveis. Assim, não é permitido o uso de tinta da china nem de qualquer outra tinta permanente de secagem rápida. De modo a garantir o bem estar de todos os participantes apelamos ainda que não sejam usadas tintas ou outros materiais com odor intenso, como diluentes, etc. Serão ainda proibidas tintas em spray."
    },
    {
        "query": "Poderei deixar os meus materiais na sala?",
        "answer": "Os trabalhos realizados poderão ser guardados no Salão Jardim de umas aulas para outras. Relativamente aos materiais, poderemos guardá-los desde que acondicionados numa mala ou capa devidamente identificados. A sala é vigiada por câmeras e a sua utilização é supervisionada pelas diferentes equipas que a usam, bem como pela equipa de segurança do Coliseu, contudo, não podemos responsabilizar-nos pelo desaparecimento ou danos de materiais. Assim que as aulas terminarem todos os materiais e trabalhos terão de ser recolhidos."
    },
    {
        "query": "Onde são as aulas?",
        "answer": "As aulas ocorrem no Salão Jardim. Esta é uma sala com cerca de 200m2 no interior do Coliseu Porto Ageas. Além de ter luz natural, conta também com luz artificial, um palco e um wc de apoio exclusivo à sala. Estará equipada com cavaletes e pranchetas para todos. A entrada faz-se pela portaria do Coliseu, na Rua Passos Manuel 137, 4000-385 Porto."
    },
    {
        "query": "Quantos alunos estão previstos por turma?",
        "answer": "O Salão Jardim, com cerca de 200 m2, tem espaço e condições para um grupo grande de trabalho sem perder a qualidade das sessões. Assim, o nosso grupo terá no mínimo 12 pessoas e no máximo 20."
    },
    {
        "query": "O que acontece se não puder ir a alguma das aulas?",
        "answer": "Neste caso, deverá avisar que irá faltar assim que lhe for possível. Teremos o cuidado de fazer um resumo do que foi falado e realizado. Caso pretenda poderemos agendar uma conversa telefónica com a professora para que se sinta a par dos conteúdos. Na aula seguinte faremos os possíveis para que recupere o ritmo."
    },
    {
        "query": "O programa é de dois meses. E se eu quiser desistir depois do primeiro mês?",
        "answer": "O programa é de dois meses e obriga ao pagamento total das 8 aulas. Facilitamos o pagamento em duas prestações mensais, contudo, ao inscrever-se fica responsável pelo pagamento da sua inscrição em todo o curso. Caso pretenda desistir, deverá, ainda assim, regularizar o pagamento total, correspondente à ocupação da sua vaga na turma."
    },
    {
        "query": "No final poderei solicitar um certificado de participação?",
        "answer": "No final todos os participantes irão receber um Diploma emitido pelo Coliseu Porto Ageas."
    },
    {
        "query": "Como devo inscrever-me e como será feito o pagamento?",
        "answer": "Para se inscrever deverá enviar um email para educativo@coliseu.pt. A equipa do Serviço Educativo Coliseu entrará em contacto consigo para informar de todos os detalhes, nomeadamente relativamente ao pagamento. Este poderá ser efetuado por uma das três vias: por transferência bancária, diretamente na bilheteira do Coliseu ou via Ticketline (neste caso com taxas adicionais)."
    },
    {
        "query": "O programa inclui uma exposição final dos retratos. É obrigatório expor o meu trabalho no final?",
        "answer": "Não, poderá escolher não participar na exposição final. Contudo, encaramos esse momento como uma celebração da multiplicidade de resultados obtidos. Certamente terá vários trabalhos realizados na altura que poderá querer mostrar e contará com a nossa ajuda para o fazer. Seria ótimo contar com a presença de todos!"
    },
    {
        "query": "Referem que o retrato final será de um artista que já tenha pisado o palco do Coliseu. Posso escolher quem quero pintar? É obrigatório ser um artista?",
        "answer": "Uma das características deste curso é o de tentar estabelecer uma ponte de proximidade entre diferentes artes, nomeadamente entre os pintores e os artistas de palco. Dependendo da vontade do grupo e das circunstâncias, poderemos todos pintar um mesmo artista, ou cada um poderá pintar um artista diferente. Em todo o caso iremos usar a nossa boa relação com os artistas que nos são próximos convidando-os a conhecer o vosso trabalho."
    },
    {
        "query": "No final do projeto, as obras expostas ficam para o Coliseu?",
        "answer": "Não. As obras realizadas pelos participantes são sempre de sua propriedade e pertença. Serão livres de os levar embora ou vender durante a exposição, caso se proporcione e o pretendam, sem qualquer envolvimento ou ganho por parte do Coliseu."
    }
]

const ExpandableButton = (props: any) => {
    const { name = "Action", children } = props
    const [open, setOpen] = useState(false)

    const handleToggle = () => {
        setOpen(!open)
    }

    const classes = clsx({ [styles.ExpandableButton]: true, [styles.Expanded]: open })
    const icon = open ? <KeyboardArrowDown /> : <KeyboardArrowRight />

    return (
        <div>
            <div onClick={handleToggle} className={classes}>
                <Grid container justify="space-between" alignItems="center" style={{ paddingLeft: 10, paddingRight: 10 }}>
                    <span></span>
                    <Typography variant="RocGroteskBold18">
                        {name}
                    </Typography>
                    {icon}
                </Grid>
            </div>
            <Show on={open}>
                <Zoom in={open}>
                    <div>
                        {children}
                    </div>
                </Zoom>
            </Show>
        </div>
    )
}

const Title = () => {

    const gtXs = useGtXs()

    const titleVariant = gtXs ? "ColisseumRegular58" : "ColisseumRegular48"
    const subtitleVariant = gtXs ? "ColisseumRegular30" : "ColisseumRegular24"

    return (
        <div className={styles.Title}>
            <Typography variant={titleVariant} paragraph>
                Atelier de Pintura
	    </Typography>
            <Typography variant={subtitleVariant} paragraph>
                Retrato: do pensamento à criação
	    </Typography>
        </div>
    )
}

const Dates = () => {
    return (
        <div className={styles.Dates}>
            <Typography variant="RocGroteskRegular20" paragraph>
                <b>Esgotado</b>
            </Typography>
            <Typography variant="RocGroteskRegular20" paragraph>
                <s>14, 15, 28, 29 <b>Maio</b></s>
            </Typography>
            <Typography variant="RocGroteskRegular20" paragraph>
                <s>11, 12, 25, 26 <b>Junho</b></s>
            </Typography>
            <div style={{ marginTop: 10 }}>
                <Typography variant="RocGroteskRegular20" paragraph>
                    <b>Nova Data</b>
                </Typography>
                <Typography variant="RocGroteskRegular20" paragraph>
                    17, 18 <b>Setembro</b>
                </Typography>
                <Typography variant="RocGroteskRegular20" paragraph>
                    1, 2, 15, 16, 29, 30 <b>Outubro</b>
                </Typography>
            </div>
        </div>
    )
}

const Description = () => {

    const gtXs = useGtXs()

    const variant = gtXs ? "RocGroteskMedium20" : "RocGroteskMedium16"
    const spacing = gtXs ? 1 : 0

    return (
        <div className={styles.Description}>

            <Paragraph variant={variant} style={{ marginBottom: 20 }}>
                O Atelier de Pintura é mais do que uma oficina tradicional. Este é um espaço imersivo onde a teoria encontra a prática numa jornada de reflexão e criação, questionando gestos, dominando técnicas, propondo rupturas, convidando a olhar, cuidadosamente, o outro, mergulhando no mundo das imagens e da subjetividade humana.
	    </Paragraph>

            <Paragraph variant={variant}>
                Nesta primeira edição, dedicada ao Retrato, a pesquisa acontece na constante procura de técnicas de controle das formas e ideias, tentando segurar identidades, personalidades, emoções, mensagens e geometrias, numa alquimia de cores e gestos sempre consequentes e significantes. O rosto, veremos, é muito mais do que um cruzamento de linhas organizadas, é preciso convocar a emoção e fazê-la habitar a imagem, dando vida aos olhares e rostos.
	    </Paragraph>

            <Grid container spacing={spacing}>

                <Grid item xs={12} sm={6}>

                    <p className={styles.ButtonWrapper}>
                        <ExpandableButton name="TEORIA">
                            <div className={styles.BulletList}>
                                <Paragraph variant={variant}>
                                    Breve história do retrato, das suas origens à contemporaneidade
				</Paragraph>
                                <Paragraph variant={variant}>
                                    As funções do retrato
				</Paragraph>
                                <Paragraph variant={variant}>
                                    Como ler o que um retrato diz? O que comunicam? Elementos de comunicação e suas mensagens.
				</Paragraph>
                                <Paragraph variant={variant}>
                                    Do corpo plural ao corpo fixo, a condensação do corpo num rosto
				</Paragraph>
                                <Paragraph variant={variant}>
                                    Retrato e Identidade: quem é aquele que habita o retrato? O outro ou eu mesmo?
				</Paragraph>
                                <Paragraph variant={variant}>
                                    Técnicas: desenho (carvão, caneta e grafite), pintura em materiais à escolha (acrílico, aguarela, pastéis)
				</Paragraph>
                                <Paragraph variant={variant}>
                                    Relação técnica-emoção: uso controlado da técnica ao serviço da emoção
				</Paragraph>
                                <Paragraph variant={variant}>
                                    Análise detalhada: cabeça, olhar, sorriso, estrutura geométrica, proporção, forma/fundo, cor, luz, sombra
				</Paragraph>
                            </div>
                        </ExpandableButton>
                    </p>

                </Grid>

                <Grid item xs={12} sm={6}>
                    <p className={styles.ButtonWrapper}>
                        <ExpandableButton name="PRÁTICA">
                            <div className={styles.BulletList}>
                                <Paragraph variant={variant}>
                                    Experimentação de diferentes técnicas e materiais
				</Paragraph>
                                <Paragraph variant={variant}>
                                    Exercícios práticos para domínio da anatomia facial
				</Paragraph>
                                <Paragraph variant={variant}>
                                    Exercícios práticos de expressão da individualidade e da visão artística de cada participante
				</Paragraph>
                                <Paragraph variant={variant}>
                                    Aconselhamento para trabalho autónomo entre sessões
				</Paragraph>
                                <Paragraph variant={variant}>
                                    Orientação na criação de um retrato final, de um artista que já tenha pisado o palco do Coliseu Porto Ageas
				</Paragraph>
                                <Paragraph variant={variant}>
                                    Exposição dos trabalhos realizados com possível presença dos artistas retratados
				</Paragraph>
                            </div>
                        </ExpandableButton>
                    </p>
                </Grid>
            </Grid>

        </div>
    )
}


const TargetAudience = () => {
    return (
        <div className={styles.TargetAudience}>
            <Typography variant="ColisseumRegular30" component="h3">
                Público-Alvo
	    </Typography>
            <Paragraph variant="RocGroteskRegular16">
                Amantes da arte e da pintura, com mais de 15 anos, com ou sem experiência prévia.
                Pessoas interessadas em aprofundar seus conhecimentos sobre a pintura de retratos.
	    </Paragraph>
        </div>
    )
}


const AdditionalInfo = () => {

    const gtXs = useGtXs()

    return (
        <div className={styles.AdditionalInfo}>
            <Typography variant="ColisseumRegular30" component="h3">
                Informações Adicionais
	    </Typography>

            {gtXs ?
                <div className={styles.AdditionalInfoContent}>
                    <Paragraph variant="RocGroteskRegular16">
                        Duração total: 20h
		 </Paragraph>
                    <Paragraph variant="RocGroteskRegular16">
                        Aulas: 8 sessões de 2h30 / 18h00 - 20h30
		 </Paragraph>
                </div>
                : <div className={styles.AdditionalInfoContent}>
                    <Paragraph variant="RocGroteskRegular16">
                        Duração total:
		</Paragraph>
                    <Paragraph variant="RocGroteskBold16">
                        20h
		</Paragraph>
                    <Paragraph variant="RocGroteskRegular16">
                        Aulas
		</Paragraph>
                    <Paragraph variant="RocGroteskBold16">
                        8 sessões de 2h30
		</Paragraph>
                    <Paragraph variant="RocGroteskBold16">
                        18h00 - 20h30
		</Paragraph>
                </div>}
        </div>
    )
}

const Prices = () => {
    return (
        <div className={styles.Prices}>
            <div>
                <Typography variant="ColisseumRegular30" component="h3">
                    Preço
		</Typography>
            </div>
            <div>
                <div className={styles.PricesContent}>
                    <Paragraph variant="RocGroteskBold16">
                        Com materiais incluídos (pronto pagamento):
		    </Paragraph>
                    <Paragraph variant="RocGroteskBold16">
                        200€
		    </Paragraph>
                    <Paragraph variant="RocGroteskRegular16">
                        relativos a 2 meses, totalizando 8 aulas.
		    </Paragraph>
                    <Paragraph variant="RocGroteskRegular16">
                        <u>ou</u> duas prestações de 110€
		    </Paragraph>
                </div>
                <div>
                    <Paragraph variant="RocGroteskBold16">
                        Sem materiais incluídos (pronto pagamento):
		    </Paragraph>
                    <Paragraph variant="RocGroteskBold16">
                        160€
		    </Paragraph>
                    <Paragraph variant="RocGroteskRegular16">
                        relativos a 2 meses, totalizando 8 aulas.
		    </Paragraph>
                    <Paragraph variant="RocGroteskRegular16">
                        <u>ou</u> duas prestações de 90€
		    </Paragraph>

                </div>
            </div>
        </div>
    )
}

const Registrations = () => {
    return (
        <div className={styles.Registrations}>
            <div>
                <Typography variant="ColisseumRegular30" component="h3">
                    Inscrições
		</Typography>
            </div>
            <div className={styles.RegistrationsContent}>
                <div>
                    <Paragraph variant="RocGroteskRegular16">
                        Pagamento a ser efetuado por uma das seguintes vias: transferência bancária, bilheteira do
			Coliseu ou <a href="https://ticketline.sapo.pt/evento/atelier-de-pintura-retrato-82732">Ticketline</a>.
		    </Paragraph>
                </div>
            </div>
        </div>
    )
}


const Materials = () => {
    return (
        <div className={styles.Materials}>
            <p className={styles.ButtonWrapper} style={{ paddingLeft: 0 }}>
                <ExpandableButton name="MATERIAIS">
                    <div style={{ marginTop: 20 }}>
                        <Paragraph variant="ColisseumRegular20">
                            Inscrição sem materiais incluídos
			</Paragraph>
                        <Paragraph variant="RocGroteskRegular18">
                            Material necessário: cada participante deverá trazer os materiais que pretende usar e cuja técnica
                            queira aprofundar. São benvindos os seguintes materiais: aguarelas, acrílico, óleo (terebentinas sem odor),
                            pastéis secos, pastéis de óleo. Cada aluno deverá trazer os seus pincéis e recipientes, avental, e um pano
                            para limpeza de mãos e dos materiais. Os primeiros trabalhos deverão realizar-se sobre papel, pelo que se
                            recomenda o uso de papel para desenho e <i>mixed media</i> para pintura. Na passagem para a pintura sobre tela será
                            solicitada uma tela com 50x70 cm.
			</Paragraph>
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <Paragraph variant="ColisseumRegular20">
                            Inscrição com materiais incluídos
			</Paragraph>
                        <Paragraph variant="RocGroteskRegular18">
                            Materiais: todos os materiais necessários para estas aulas estão incluídos, para uso exclusivo em sala, nomeadamente papel para desenho, papel mixed media, pincéis, tinta acrílica, aguarela, pastel de óleo, cavalete, prancheta. Os participantes podem, contudo, trazer os seus próprios materiais, atendendo às suas preferências e vontades. Os primeiros trabalhos deverão realizar-se sobre papel. A passagem para a pintura sobre tela terá a oferta de uma tela por participante.
			</Paragraph>
                        <div style={{ marginTop: 20 }}>
                            <Paragraph variant="RocGroteskRegular18">
                                Cada participante deverá trazer o seu próprio avental, ou qualquer outro vestuário de proteção, e panos para limpeza das mãos e dos pincéis durante a prática.
			    </Paragraph>
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Paragraph variant="RocGroteskRegular18">
                                Materiais proibidos: tinta da china e outras tintas permanentes ou com odor intenso.
			    </Paragraph>
                        </div>
                    </div>
                </ExpandableButton>
            </p>
        </div>
    )
}

const CallToAction1 = () => {
    return (
        <div className={styles.CallToAction1}>
            <Paragraph variant="RocGroteskBold25">
                Mais do que uma oficina, o Atelier de Pintura - Retrato é uma experiência transformadora que convida a
                explorar a arte como ferramenta de expressão, reflexão e autoconhecimento.
	    </Paragraph>
            <Paragraph variant="RocGroteskBold25">
                Junte-se a nós!
	    </Paragraph>
        </div>
    )
}

const Teacher = () => {

    const gtXs = useGtXs()
    const variantTitle = gtXs ? "RocGroteskBold20" : "RocGroteskBold16"
    const variantSubtitle = gtXs ? "RocGroteskRegular20" : "RocGroteskRegular16"
    const variant = gtXs ? "RocGroteskRegular18" : "RocGroteskRegular18"

    return (
        <div className={styles.Teacher}>
            <Paragraph variant={variantTitle}>
                Filipa Godinho
	    </Paragraph>
            <Paragraph variant={variantSubtitle}>
                Professora do Atelier de Pintura Retrato
	    </Paragraph>
            <div className={styles.Photo}>

            </div>
            <div>
                <p className={styles.ButtonWrapper}>
                    <ExpandableButton name="BIOGRAFIA">
                        <div style={{ marginTop: 20 }}>
                            <Paragraph variant={variant}>
                                Licenciada em Artes Plásticas - Pintura, pela Faculdade de Belas Artes da Universidade do Porto e mestrada
                                em Práticas Artísticas Contemporâneas na mesma instituição, onde tem em curso o Doutoramento.
			    </Paragraph>
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Paragraph variant={variant}>
                                Professora desde 2006, alterna a sua prática profissional entre projetos educativos e a prática artística
                                de criação individual, nos campos da pintura e da escultura. Expõe com regularidade desde 2006 contando
                                com obras suas em várias coleções nacionais e internacionais.
			    </Paragraph>
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Paragraph variant={variant}>
                                O retrato tem sido o campo de trabalho onde mais se tem destacado, sobretudo através de projetos
                                comissariados para algumas das mais prestigiadas instituições e galerias do país. Desde os retratos
                                a óleo, institucionais e honoríficos, até aos retratos a aguarela mais leves, íntimos e espontâneos,
                                a sua prática destaca-se pela versatilidade nas técnicas e abordagens que combina, bem como pelo rigor
                                do desenho figurativo académico.
			    </Paragraph>
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Paragraph variant={variant}>
                                Professora há mais de 20 anos, desde o primeiro ciclo ao ensino superior, nas áreas do desenho, geometria, fotografia e pintura.
			    </Paragraph>
                        </div>
                    </ExpandableButton>
                </p>
            </div>
        </div>
    )
}

const CallToAction2 = () => {
    return (
        <div className={styles.CallToAction1}>
            <Paragraph variant="RocGroteskBold25">
                Não perca esta oportunidade de mergulhar no fascinante mundo da pintura de retratos!
	    </Paragraph>
        </div>
    )
}

const Logos = () => {
    return (
        <div className={styles.Logos}>
            <Paragraph variant="RocGroteskRegular16">
                Este programa conta com o apoio:
	    </Paragraph>
            <div>
                <img src={logos} alt="logos apoios" style={{ maxWidth: "100%", textAlign: "center" }} />
            </div>
        </div>
    )
}

const FrequentQuestion = (props: any) => {
    const { question } = props
    const [open, setOpen] = useState<boolean>(false)

    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <div onClick={handleClick} className={styles.FrequentQuestion}>
            <Paragraph variant="RocGroteskMedium16" className={styles.Query} style={{ borderBottom: open ? "1px solid #000000" : "" }}>
                {question.query}
            </Paragraph>
            <Show on={open}>
                <Paragraph variant="RocGroteskMedium16" className={styles.Answer}>
                    {question.answer}
                </Paragraph>
            </Show>
        </div>
    )
}


const FrequentQuestions = () => {
    const gtXs = useGtXs()

    const variant = gtXs ? "RocGroteskRegular30" : "RocGroteskRegular25"

    return (
        <div className={styles.FrequentQuestions}>
            {
                gtXs
                    ?

                    <Paragraph variant={variant} style={{ paddingBottom: 10 }}>
                        Questões Frequentes (FAQ)
		</Paragraph>

                    :

                    <>
                        <Paragraph variant={variant} >
                            Questões Frequentes
		    </Paragraph>
                        <Paragraph variant={variant} style={{ paddingBottom: 10 }}>
                            (FAQ)
		    </Paragraph>
                    </>

            }
            <div>
                {questions.map(question => <FrequentQuestion key={question.query} question={question} />)}
            </div>
        </div>
    )
}


export default function PaintingStudioView() {

    const gtXs = useGtXs()

    return (
        <div>
            <div className={styles.SectionBanner} />
            <Grid container justify="center" className={styles.Wrapper}>
                <main>

                    {gtXs ?

                        <Grid container spacing={10}>

                            <Grid item xs={5}>
                                <Dates />
                                <TargetAudience />
                                <AdditionalInfo />
                                <Prices />
                                <Registrations />
                                <Materials />
                            </Grid>

                            <Grid item xs={7}>
                                <Title />
                                <Description />
                                <CallToAction1 />
                                <Teacher />
                                <CallToAction2 />
                                <Logos />
                            </Grid>

                        </Grid>

                        :

                        <Grid container justify="space-between" className={styles.Presentation}>
                            <Grid item xs={12}>
                                <Title />
                                <Dates />
                                <Description />
                                <TargetAudience />
                                <AdditionalInfo />
                                <Prices />
                                <Registrations />
                                <Materials />
                                <CallToAction1 />
                                <Teacher />
                                <CallToAction2 />
                                <Logos />
                            </Grid>
                        </Grid>
                    }
                </main>

            </Grid>
            <Grid container justify="center" className={styles.FrequentQuestionsWrapper}>
                <FrequentQuestions />
            </Grid>
        </div>
    )
}
