import React from 'react';
import { useHistory } from 'react-router';
import { EduActivityPreviewType, getEducationalAreaById } from '../SchoolsView/Activities';
import styles from './EduActivityPreview.module.scss';

export function EduActivityPreview(props: EduActivityPreviewType) {
    const { slug, name, cover, category, targetAudience, areasIds } = props

    const history = useHistory()

    const handleClick = () => {
        history.push(`/educativo/programa-escolas/atividade/${slug}`)
    }

    return (
        <div className={styles.EduActivityPreview} onClick={handleClick}>
            <img src={cover} alt={name} />
            <div className={styles.ActivityAreas}>
                {areasIds.map(areaId => {
                    const area = getEducationalAreaById(areaId)
                    return area ? <img src={area.icon} title={area.name} alt={area.name} /> : null
                })
                }
            </div>

            <div className={styles.Category}>
                {category}
            </div>
            <div>{targetAudience}</div>
        </div>
    )
}
