import { Grid, Tooltip } from '@material-ui/core'
import React from 'react'
import { Link } from '../Link/Link'
import OutlineButton from '../OutlineButton/OutlineButton'
import Typography, { ExtendedTypographyVariant } from '../Typography/Typography'
import styles from './ThreeColumnSection.module.scss'

interface ThreeColumnContentImageProps {
    image: string
    route: string
}

const ThreeColumnContentImage = (props: ThreeColumnContentImageProps) => {
    const { image, route } = props
    return (
        <Link route={route}>
            <div className={styles.Placeholder}>
                <img src={image} alt="" />
            </div>
        </Link>
    )
}

interface ThreeColumnContentItem {
    id: number,
    date: string
    name: string
    image: string
    route: string
}

interface ThreeColumnSectionAction {
    name: string
    route: string
    linkName: string
}

type ContentStyleProp = "short" | "long"

interface ThreeColumnSectionProps {
    content: ThreeColumnContentItem[]
    action: ThreeColumnSectionAction
    contentStyle?: ContentStyleProp
}

function getStyle(contentStyle: ContentStyleProp) {
    const shortStyle = { variant: "menu", cls: styles.Name }
    const longStyle = { variant: "subtitle2", cls: styles.Text }
    return contentStyle === "short" ? shortStyle : longStyle
}

export default function ThreeColumnSection(props: ThreeColumnSectionProps) {

    const { content, action: { name, route, linkName }, contentStyle = "short" } = props

    const style = getStyle(contentStyle)

    // tem que ser obrigatoriamente dois itens
    const selectedContent = content

    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid container component="section" direction="column">
                <Grid container justify="space-between">
                    {selectedContent.map(item =>
                        <div key={item.id} className={styles.Third}>
                            <Typography variant="subtitle1">
                                {item.date}
                            </Typography>
                        </div>

                    )}
                    <div className={styles.Third} />
                </Grid>

                <Grid container justify="space-between">

                    {selectedContent.map(item =>
                        <div key={item.id} className={styles.Third}>
                            <ThreeColumnContentImage image={item.image} route={item.route} />
                        </div>
                    )}

                    <div className={styles.Third}>
                        <Grid container direction="column" justify="space-between" style={{ height: "100%" }}>
                            <div>
                                <Typography variant="menu" style={{ fontSize: 70, lineHeight: 0.8 }}>
                                    {name}
                                </Typography>
                            </div>
                            <OutlineButton route={route} name={linkName} />
                        </Grid>
                    </div>
                </Grid>

                <Grid container justify="space-between">
                    {selectedContent.map(item =>
                        <Tooltip key={item.id} title={item.name}>
                            <div className={styles.Third}>
                                <Typography variant={style.variant as ExtendedTypographyVariant} className={style.cls}>
                                    <Link route={item.route} name={item.name} />
                                </Typography>
                            </div>
                        </Tooltip>

                    )}
                    <div className={styles.Third} />
                </Grid>
            </Grid>
        </Grid>
    )
}
