import { Grid } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import clsx from 'clsx'
import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import useCarousel from '../../hooks/useCarousel'
import { HomeEducationalAction, HomeEducationalContent } from '../HomeEducationalService/HomeEducationalService'
import HomeEducationalServiceMobileItem from '../HomeEducationalServiceMobileItem/HomeEducationalServiceMobileItem'
import OutlineButton from '../OutlineButton/OutlineButton'
import Typography from '../Typography/Typography'
import styles from './HomeEducationalServiceMobile.module.scss'

interface HomeEducationalServiceMobileProps {
    content: HomeEducationalContent[]
    action: HomeEducationalAction
}

export default function HomeEducationalServiceMobile(props: HomeEducationalServiceMobileProps) {

    const { content, action } = props
    const { next, previous, currentSlide, isStart, isEnd, carouselProps } = useCarousel(0, 1)

    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid container justify="center">
                <Typography variant="menu" className={styles.SectionName}>
                    Serviço Educativo Coliseu
		</Typography>
            </Grid>
            <Grid container direction="column" justify="center" alignContent="center" className={styles.Arrow} onClick={previous}>
                <ArrowBackIosIcon className={clsx({ [styles.Disabled]: isStart })} />
            </Grid>
            <Carousel
                {...carouselProps}
                className={styles.Carousel}
                selectedItem={currentSlide}
                onChange={() => { }}
            >
                {content?.map(item => <HomeEducationalServiceMobileItem key={item.id} item={item} />)}
            </Carousel>
            <Grid container direction="column" justify="center" alignContent="center" className={styles.Arrow} onClick={next}>
                <ArrowForwardIosIcon className={clsx({ [styles.Disabled]: isEnd })} />
            </Grid>
            <Grid container justify="center">
                <Grid container justify="center" className={styles.OutlineButtonWrapper}>
                    <OutlineButton route={action.route} name={action.linkName} />
                </Grid>
            </Grid>

        </Grid>
    )
}
