import { Grid } from '@material-ui/core';
import React from 'react';
import Paragraph from '../Paragraph/Paragraph';
import Typography from '../Typography/Typography';
import styles from './BalleteatroView.module.scss';

export default function BalleteatroView() {
    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid item xs={12} sm={4} className={styles.Image} />
            <Grid item xs={12} sm={8} className={styles.Text}>
                <main>
                    <Typography variant="h1colisseum">
                        Balleteatro - Estrutura Artística Residente
		    </Typography>

                    <section>
                        <Paragraph>
                            O Balleteatro é, desde setembro de 2015, a estrutura artística residente do Coliseu Porto. Há já vários anos que o edifício do Coliseu mantinha um conjunto de salas e espaços vazios, que fazia todo o sentido que fossem abertos e disponibilizados aos cidadãos. Receber uma escola artística nas instalações do Coliseu revelou-se a forma perfeita de habitar este espaço e de o devolver em definitivo à cidade. Agora, o Coliseu é diariamente revitalizado pela presença de alunos e professores, que enchem as salas de teoria do Teatro e da Dança, práticas artísticas e ensaios.
			</Paragraph>

                        <a href="https://balleteatro.pt" target="_blank" rel="noopener noreferrer">www.balleteatro.pt</a>
                    </section>
                </main>
            </Grid>
        </Grid>
    )
}
