import { Grid } from '@material-ui/core'
import React from 'react'
import novoAtico from '../../assets/images/institution/spaces/novo_atico.jpg'
import salaoJardim from '../../assets/images/institution/spaces/salao_jardim.jpg'
import salaPrincipal from '../../assets/images/institution/spaces/sala_principal.jpg'
import foyer from '../../assets/images/institution/spaces/foyer.jpg'
import Paragraph from '../Paragraph/Paragraph';
import Typography from '../Typography/Typography';
import styles from './SpacesView.module.scss';

export default function SpacesView() {
    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <main>

                <Typography variant="h1colisseum">
                    Espaços
                </Typography>

                <section>

                    <div className={styles.TextImage}>
                        <img src={salaPrincipal} alt="" className={styles.PageImage} />
                        <Typography variant="h6">
                            Sala Principal
                        </Typography>
                        <Paragraph>Com uma lotação de 3.000 lugares sentados e 4.000 de pé, a sala principal do Coliseu Porto tem possibilidades infinitas. A decoração de estilo clássico coabita com os equipamentos e tecnologias mais recentes, criando ao mesmo tempo um ambiente único e totalmente preparado para receber os melhores espetáculos mundiais. Esta sala, pela sua versatilidade, está também disponível para acolher eventos corporativos ou iniciativas de múltipla natureza. O Coliseu, na sua diversidade de funções, dispõe ainda de uma pista de circo com características únicas no país e dois fossos de orquestra.</Paragraph>
                    </div>

                    <div className={styles.TextImage}>
                        <img src={salaoJardim} alt="" className={styles.PageImage} />
                        <Typography variant="h6">Salão Jardim</Typography>
                        <Paragraph>Até há pouco tempo conhecido como Bar do Coliseu, o Salão Jardim recupera o nome original de 1941. Situado no piso térreo, ao nível da plateia, é um espaço mais intimista. Pode ser utilizado para eventos de menor dimensão, como concertos, showcases, exposições, conversas, reuniões e apresentações.</Paragraph>
                        <Paragraph>
                            Capacidade: 150 pessoas<br />
                            Área: 250 m²<br />
                            Comprimento: 20 m<br />
                            Largura: 12,5 m<br />
                            Equipamento de som<br />
                            Bar de apoio
                        </Paragraph>
                    </div>

                    <div className={styles.TextImage}>
                        <img src={novoAtico} alt="" className={styles.PageImage} />
                        <Typography variant="h6">Novo Ático</Typography>
                        <Paragraph>Situa-se no piso superior do Coliseu Porto Ageas e é um espaço vocacionado para acolher eventos de média dimensão. Anteriormente conhecida como Sala Dois, faz parte do projeto original do Coliseu e permite uma grande flexibilidade na disposição do mobiliário e na montagem de diversas soluções de iluminação e som. Esta sala está preparada para espetáculos, concertos, recitais, exposições, conferências, sessões de cinema, apresentações e até jantares e festas.</Paragraph>
                        <Paragraph>
                            Capacidade: 300 pessoas<br />
                            Área: 659 m²<br />
                            Palco e púlpito<br />
                            Piano<br />
                            Equipamento de luz e som<br />
                            Bar, copa e cozinha<br />
                            Camarins<br />
                        </Paragraph>
                    </div>

                    <div className={styles.TextImage}>
                        <img src={foyer} alt="" className={styles.PageImage} />
                        <Typography variant="h6">Foyer</Typography>
                        <Paragraph>
                            O Foyer ergue-se de nobreza para dar as boas-vindas a quem entra no Coliseu.
                            Entre os quatro baixos-relevos do escultor Henrique Moreira e um dos raros relevos que
                            ainda ostentam o antigo brasão da cidade, encimado pela coroa e pelo dragão, o Foyer
                            é um espaço de apoio a grandes eventos e permite ativações de marca e cocktails, mas
                            também apresentações corporativas.
                        </Paragraph>
                        <Paragraph>
                            Capacidade: 100 pessoas sentadas<br />
                            Área: 250 m²<br />
                            Ideal para: eventos, apresentações, corporativos, cocktails<br />
                        </Paragraph>
                    </div>
                </section>
            </main>
        </Grid>
    )
}
