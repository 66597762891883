import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import getSectionSubsections from '../Sitemap/Helpers/getSectionSubsections';
import Submenu from '../Submenu/Submenu';
import styles from './InstitutionView.module.scss';

interface InstitutionViewProps extends RouteComponentProps<{}> { }

export default function InstitutionView(props: InstitutionViewProps) {

    const { match } = props

    const subsections = getSectionSubsections(3)
    const subsection = subsections.find(subsection => subsection.route === match.path)
    const component = subsection ? subsection.component : null

    return (
        <div className={styles.Wrapper}>
            <Submenu items={subsections} path={match.path} />
            {component}
        </div>
    )
}
