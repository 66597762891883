import React from 'react';
import BlackButton from '../BlackButton/BlackButton';
import StandardPage from '../StandardPage/StandardPage';
import styles from './ToursView.module.scss';

export default function ToursView() {
    return (
        <div>
            <div className={styles.SectionBanner} />
            <StandardPage>
                <div className={styles.Tours}>
                    <h1>Brevemente</h1>
                    <div className={styles.CallToAction}>
                        <p>Siga a newsletter do Coliseu para informações em primeira mão</p>
                    </div>
                    <div className={styles.Action}>
                        <BlackButton text="Subscrever Newsletter" to="/newsletter/subscrever" />
                    </div>
                </div>
            </StandardPage >
        </div>
    )
}
