import { Grid } from '@material-ui/core'
import React from 'react'
import { HomeEducationalContent } from '../HomeEducationalService/HomeEducationalService'
import { Link } from '../Link/Link'
import Typography from '../Typography/Typography'
import styles from './HomeEducationalServiceMobileItem.module.scss'

interface HomeEducationalServiceMobileItemProps {
    item: HomeEducationalContent
}

export default function HomeEducationalServiceMobileItem(props: HomeEducationalServiceMobileItemProps) {

    const { date, name, image, route } = props.item

    return (
        <Grid container direction="column" className={styles.Wrapper}>
            <Grid container>
                <Typography variant="subtitle2" gutterBottom={false}>
                    {date}
                </Typography>
            </Grid>
            <Grid container className={styles.Image}>
                <Link route={route}>
                    <img src={image} alt={name} />
                </Link>
            </Grid>
            <Grid container>
                <Typography variant="menu" className={styles.Name}>
                    <Link name={name} route={route} />
                </Typography>
            </Grid>
        </Grid>
    )
}
