import { Grid } from '@material-ui/core';
import { clsx } from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './BlackButton.module.scss';

interface BlackButtonProps {
    text: string,
    to?: string
    onClick?: (event: any) => any
    fullWidth?: boolean
}

export default function BlackButton(props: BlackButtonProps) {
    const { text, to, onClick, fullWidth = false } = props

    const classes = clsx({ [styles.FullWidth]: fullWidth, [styles.Regular]: !fullWidth })
    const component = to ? <Link to={to} className={classes}>{text}</Link> : <span className={classes}>{text}</span>

    return (
        <Grid container justify="center" className={styles.BlackButton} onClick={onClick}>
            {component}
        </Grid>

    )
}
