import acesso_reservado from '../../assets/images/educational/areas/acesso_reservado.svg';
import artes_plasticas from '../../assets/images/educational/areas/artes_plasticas.svg';
import dialogo from '../../assets/images/educational/areas/dialogo.svg';
import escrita from '../../assets/images/educational/areas/escrita.svg';
import jogo from '../../assets/images/educational/areas/jogo.svg';
import teatro from '../../assets/images/educational/areas/teatro.svg';
import backstage_palco_das_historias from '../../assets/images/educational/footer/backstage_palco_das_historias.webp';
import coliseu_palavras from '../../assets/images/educational/footer/coliseu_das_palavras.webp';
import magia_das_sombras from '../../assets/images/educational/footer/magia_das_sombras.webp';
import oficina_palco_das_historias from '../../assets/images/educational/footer/oficina_palco_das_historias.webp';
import passaporte_coliseu from '../../assets/images/educational/footer/passaporte_coliseu.webp';
import vista_palco_das_historias from '../../assets/images/educational/footer/vista_palco_das_historias.webp';
import banner1 from '../../assets/images/educational/schools/banner_visita_jogo.webp';
import banner2 from '../../assets/images/educational/schools/banner_visitas_orientadas.webp';
import banner3 from '../../assets/images/educational/schools/banner_oficinas.webp';
import banner4 from '../../assets/images/educational/schools/banner_formacao_professores.webp';


export type EduActivityPreviewType = {
    id: number
    name: string
    slug: string
    areas: string
    areasIds: number[]
    category: string
    categoryId: number
    targetAudience: string
    targetAudienceIds: number[]
    link: string
    duration: string
    capacity: string
    booking: string
    price: string
    description: string
    cover: string
    relatedActivities: number[]
}

type EducationalArea = {
    id: number
    name: string
    icon: string
}

export type EducationalCategory = {
    id: number
    name: string
    slug: string
    cover: string
}

export const educationalCategories: EducationalCategory[] = [
    { id: 1, name: "Visita Jogo", slug: "visita-jogo", cover: banner1 },
    { id: 2, name: "Visitas Orientadas", slug: "visitas-orientadas", cover: banner2 },
    { id: 3, name: "Oficinas", slug: "oficinas", cover: banner3 },
    { id: 4, name: "Formação Professores", slug: "formacao-professores", cover: banner4 },
]

export const educationalAreas: EducationalArea[] = [
    { id: 1, name: "Teatro", icon: teatro },
    { id: 2, name: "Artes Plásticas", icon: artes_plasticas },
    { id: 3, name: "Escrita", icon: escrita },
    { id: 4, name: "Jogo", icon: jogo },
    { id: 5, name: "Diálogo", icon: dialogo },
    { id: 6, name: "Acesso Reservado", icon: acesso_reservado }
]

export const targetAudiences = [
    { id: 1, name: "Geral" },
    { id: 2, name: "Pré-Escolar" },
    { id: 3, name: "1.º Ciclo" },
    { id: 4, name: "2.º Ciclo" },
    { id: 5, name: "3.º Ciclo" },
    { id: 6, name: "Secundário, Superior e Profissional" },
]

export function getEducationalAreaById(id: number): EducationalArea | undefined {
    return educationalAreas.find(area => area.id === id);
}

export function getActivityById(id: number): EduActivityPreviewType | undefined {
    return activities.find(activity => activity.id === id);
}

export function getCategoryBySlug(slug: string): EducationalCategory | undefined {
    return educationalCategories.find(category => category.slug === slug)
}

export function excludeCategoryFromList(category: EducationalCategory): EducationalCategory[] {
    return educationalCategories.filter(item => item.id !== category.id)
}


export const activities: EduActivityPreviewType[] = [
    {
        id: 1,
        name: "O Coliseu das Palavras",
        slug: "coliseu-palavras",
        areas: "jogo, diálogo",
        areasIds: [4, 5],
        category: "Visita Jogo",
        categoryId: 1,
        targetAudience: "Pré-escolar; 1º ciclo",
        targetAudienceIds: [2, 3],
        link: "",
        duration: "1h00",
        capacity: "16 a 25 participantes",
        booking: "2ª a 6ª (9h00 - 18h00)",
        price: "3€/participante",
        description: "<p>Esta é uma visita especial na qual os participantes são conduzidos pelos diferentes espaços do icónico edifício do Coliseu Porto Ageas, tentando descobrir as palavras e expressões que definem o léxico deste edifício classificado e das suas artes. Desde a porta de acesso dos artistas, passando pelo auditório, camarotes e corredores, foyer e entrada do público, todos terão um papel participativo na descoberta do glossário que se associa ao Coliseu.</p>",
        cover: coliseu_palavras,
        relatedActivities: [2, 3, 4, 5, 6]
    },
    {
        id: 2,
        name: "Passaporte Coliseu",
        slug: "passaporte-coliseu",
        areas: "jogo, diálogo",
        areasIds: [4, 5],
        category: "Visita Jogo",
        categoryId: 1,
        targetAudience: "1.º ciclo, 2.º ciclo e 3.º ciclo",
        targetAudienceIds: [3, 4, 5],
        link: "",
        duration: "1h00",
        capacity: "16 a 25 participantes",
        booking: "2ª a 6ª (9h00 - 18h00)",
        price: "3€/participante",
        description: "<p>Quantos palcos tem o Coliseu? Que grandes nomes já os pisaram? Esta é a visita perfeita para os mais curiosos. Vamos percorrer um complexo labirinto de salas, escadas e corredores, munidos de um passaporte que nos ajudará a descobrir as suas histórias, antigas, novas, misteriosas, surpreendentes! É de lápis na mão, entre palavras e desenhos, que fazemos esta viagem descobrindo o passado, observando o presente e sonhando o futuro do Coliseu.</p>",
        cover: passaporte_coliseu,
        relatedActivities: [1, 3, 4, 5, 6]
    },
    {
        id: 3,
        name: "Palco das Historias",
        slug: "palco-historias",
        areas: "diálogo",
        areasIds: [5],
        category: "Visitas Orientadas",
        categoryId: 2,
        targetAudience: "Ensino secundário, profissional e superior",
        targetAudienceIds: [6],
        link: "",
        duration: "1h00",
        capacity: "16 a 25 participantes",
        booking: "2ª a 6ª (9h00 - 18h00)",
        price: "3€/participante",
        description: "<p>O palco é o lugar de todas as histórias e o Coliseu já foi palco de muitas. Esta pode ser uma visita múltipla, customizada, que permite escolher o foco do discurso em áreas particulares como a arquitetura, a história e o contexto sociocultural que envolvem o Coliseu, ou uma visita geral, que atravessa todos os temas de modo abrangente. Atendendo aos interesses e áreas de estudo de cada professor ou grupo, damos palco a diferentes olhares sobre este riquíssimo lugar que é parte da identidade cultural da cidade do Porto e do país.</p> <p><b>ARQUITETURA:</b> Enquadramento histórico, o modernismo, os arquitetos e tendências, o programa inicial, materiais, tecnologias, plantas, características acústicas</p> <p><b>HISTÓRIA E CULTURA:</b> Visão histórica, política, social e cultural Personalidades, características, objetivos da instituição e sua importância no panorama nacional e internacional.</p> <p><b>GERAL:</b> Soma de todos os temas através de uma visão ampla do enquadramento sociocultural, histórico e arquitetónico do Coliseu, atendendo às suas histórias e acontecimentos</p>",
        cover: vista_palco_das_historias,
        relatedActivities: [1, 2, 4, 5, 6]
    },
    {
        id: 4,
        name: "Backstage Palco das Histórias",
        slug: "backstage-palco-historias",
        areas: "acesso reservado, diálogo",
        areasIds: [6, 5],
        category: "Visitas Orientadas",
        categoryId: 2,
        targetAudience: "2º ciclo, 3ºciclo, ensino secundário, profissional e superior",
        targetAudienceIds: [4, 5, 6],
        link: "",
        duration: "1h00",
        capacity: "16 a 25 participantes",
        booking: "2ª (9h00 - 18h00)",
        price: "4,5€/participante",
        description: "<p>Se o palco é lugar de magia, esta é a visita que revela como se fazem todos os truques.Desde o auditório ao palco, dos camarotes aos camarins, passando pelas inúmeras cordas e cortinas, escadas, cadeiras, labirintos e corredores subterrâneos, a experiência é total.Este acesso exclusivo aos bastidores do Coliseu faz- se acompanhar por um sem fim de histórias, desde a entrada e saída dos elefantes no circo antigo até à montagem e desmontagem de grandes eventos, de alguns dos maiores nomes da cultura nacional e internacional.Como se monta um espetáculo ? Como funciona o Coliseu ? No backstage encontramos as respostas.</p>",
        cover: backstage_palco_das_historias,
        relatedActivities: [1, 2, 3, 5, 6]

    },
    {
        id: 5,
        name: "A magia das sombras",
        slug: "magia-das-sombras",
        areas: "teatro, artes plásticas",
        areasIds: [1, 2],
        category: "Oficinas",
        categoryId: 3,
        targetAudience: "Pré-escolar; 1º ciclo",
        targetAudienceIds: [2, 3],
        link: "",
        duration: "1h30",
        capacity: "20 a 25 participantes",
        booking: "3ª a 6ª (9h00 - 12h30)",
        price: "4,5€/participante",
        description: "<p>Esta oficina faz-se de ilusão: escurecemos a sala, inventamos sombras e, no fim, nascem histórias! Desde figuras de sombras feitas com as mãos à construção de personagens e narrativas, vamos dar palco à imaginação. Narradores, personagens, cenários, há muito para organizar nesta intensa experiência de produção de espetáculos!</p>",
        cover: magia_das_sombras,
        relatedActivities: [1, 2, 3, 4, 6]
    },
    {
        id: 6,
        name: "O palco das histórias",
        slug: "oficina-palco-das-historias",
        areas: "jogo",
        areasIds: [4, 1, 3],
        category: "Oficinas",
        categoryId: 3,
        targetAudience: "2º ciclo, 3º circlo",
        targetAudienceIds: [4, 5],
        link: "",
        duration: "1h30",
        capacity: "20 a 25 participantes",
        booking: "3ª a 6ª (9h00 - 12h30)",
        price: "4,5€/participante",
        description: "<p>Nesta oficina, vamos fazer muitas perguntas e, sobretudo, contar muitas histórias! Entre palavras e objetos, queremos encontrar os ingredientes certos para as nossas narrativas, surpreender todos com imaginação e, claro, talentopara as contar! Vamos dar palco às histórias?</p>",
        cover: oficina_palco_das_historias,
        relatedActivities: [1, 2, 3, 4, 5]
    }
]
