import { Grid } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './GoBackToSchool.module.scss';

export default function GoBackToSchool() {
    return (
        <div className={styles.GoBackToSchool}>
            <div className={styles.Content}>
                <Link to="/educativo/programa-escolas">
                    <Grid container className={styles.GoBackButton} alignItems="center">
                        <ChevronLeft /> <span>Programa Escolas 2023/24</span>
                    </Grid>
                </Link>
            </div>
        </div>
    )
}
