import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Helmet } from "react-helmet";
import photo1 from '../../../assets/images/circus/2018/photos/coliseu_porto_ageas_circo_2018_01.jpg';
import photo2 from '../../../assets/images/circus/2018/photos/coliseu_porto_ageas_circo_2018_02.jpg';
import photo3 from '../../../assets/images/circus/2018/photos/coliseu_porto_ageas_circo_2018_03.jpg';
import photo4 from '../../../assets/images/circus/2018/photos/coliseu_porto_ageas_circo_2018_04.jpg';
import photo5 from '../../../assets/images/circus/2018/photos/coliseu_porto_ageas_circo_2018_05.jpg';
import photo6 from '../../../assets/images/circus/2018/photos/coliseu_porto_ageas_circo_2018_06.jpg';
import photo7 from '../../../assets/images/circus/2018/photos/coliseu_porto_ageas_circo_2018_07.jpg';
import photo8 from '../../../assets/images/circus/2018/photos/coliseu_porto_ageas_circo_2018_08.jpg';
import photo9 from '../../../assets/images/circus/2018/photos/coliseu_porto_ageas_circo_2018_09.jpg';
import photo10 from '../../../assets/images/circus/2018/photos/coliseu_porto_ageas_circo_2018_10.jpg';
import photo11 from '../../../assets/images/circus/2018/photos/coliseu_porto_ageas_circo_2018_11.jpg';
import photo12 from '../../../assets/images/circus/2018/photos/coliseu_porto_ageas_circo_2018_12.jpg';
import photo13 from '../../../assets/images/circus/2018/photos/coliseu_porto_ageas_circo_2018_13.jpg';
import photo14 from '../../../assets/images/circus/2018/photos/coliseu_porto_ageas_circo_2018_14.jpg';
import photo15 from '../../../assets/images/circus/2018/photos/coliseu_porto_ageas_circo_2018_15.jpg';
import photo16 from '../../../assets/images/circus/2018/photos/coliseu_porto_ageas_circo_2018_16.jpg';
import photo17 from '../../../assets/images/circus/2018/photos/coliseu_porto_ageas_circo_2018_17.jpg';
import photo18 from '../../../assets/images/circus/2018/photos/coliseu_porto_ageas_circo_2018_18.jpg';
import { Config } from '../../../services/Config';
import CircusPhotoGallery from '../CircusPhotoGallery/CircusPhotoGallery';
import CircusRow from '../CircusRow/CircusRow';
import artists from './Artists.json';
import styles from './Circus2018View.module.scss';



const Circus2018View = () => {

    const photos = [
        photo1,
        photo2,
        photo3,
        photo4,
        photo5,
        photo6,
        photo7,
        photo8,
        photo9,
        photo10,
        photo11,
        photo12,
        photo13,
        photo14,
        photo15,
        photo16,
        photo17,
        photo18
    ];

    return (
        <>
            <Helmet>
                <meta property="og:site_name" content="Coliseu Porto Ageas" />
                <meta property="og:url" content={`${Config.APP}circo-2018`} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Coliseu Porto Ageas" />
                <meta property="og:description" content="A caravana do Circo chega no dia 14 de dezembro com alguns dos melhores artistas do mundo atualmente em digressão. Completamente programado pelo Coliseu, o cartaz reúne talentos prontos a mostrar, diante dos olhos do público de todas as idades, o que as artes circenses têm de mais fantástico e autêntico." />
                <meta property="og:image" content={`${Config.APP}assets/images/circus/2018/circo2018_social.jpg}`} />
                <meta property="og:image:width" content="658" />
                <meta property="og:image:height" content="540" />
            </Helmet>

            <div className={styles.Circus2018}>
                <div className={styles.Hero}>
                    <h1>Chegou o Circo!</h1>
                    <h2>O Coliseu Porto Ageas apresenta o seu Circo de Natal,
			uma verdadeira festa da felicidade e do fantástico, e sem
			animais em pista desde 2015!
		    </h2>
                </div>

                <Grid container className={styles.Intro} justify="center">
                    <h3>A caravana do Circo chega no dia 14 de dezembro com
			alguns dos melhores artistas do mundo atualmente em
			digressão. Completamente programado pelo Coliseu, o
			cartaz reúne talentos prontos a mostrar, diante dos olhos
			do público de todas as idades, o que as artes circenses
			têm de mais fantástico e autêntico. Um espetáculo capaz
			de reinterpretar os clássicos da tradição circense aliando-os
			às disciplinas mais contemporâneas, resultando em
			números de extraordinária beleza. Uma tradição que o Coliseu
			mantém viva desde 1941, e que todos os anos atrai
			100 mil pessoas.</h3>
                </Grid>

                {artists.map(artist => <CircusRow key={Math.random()} {...artist} />)}
                <CircusPhotoGallery photos={photos} year={2018} />
            </div>
        </>
    );

};

export default Circus2018View;
