import { Grid } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import BlackButton from '../BlackButton/BlackButton';
import EducationalHero from '../EducationalHero/EducationalHero';
import GoBackToSchool from '../GoBackToSchool/GoBackToSchool';
import RelatedEducationalActivities from '../RelatedEducationalActivities/RelatedEducationalActivities';
import { activities, EduActivityPreviewType, getEducationalAreaById } from '../SchoolsView/Activities';
import styles from './EducationalActivityView.module.scss';


interface DetailsAndAreasProps {
    activity: EduActivityPreviewType
}

export const ActivityName = (props: DetailsAndAreasProps) => {
    const { activity } = props
    return (
        <h1 className={styles.Name}>
            {activity.name}
        </h1>
    )
}

export const DetailsAndAreas = (props: DetailsAndAreasProps) => {
    const { activity } = props
    return (
        <>
            <section id={styles.Details}>
                <p>
                    <span>Público:</span> {activity.targetAudience}
                </p>
                <p>
                    <span>Duração:</span> {activity.duration}
                </p>
                <p>
                    <span>Lotação:</span> {activity.capacity}
                </p>
                <p>
                    <span>Marcações:</span> {activity.booking}
                </p>
                <p>
                    <span>Preço:</span> {activity.price}
                </p>
            </section>


            <div className={styles.ActivityAreas}>
                {activity.areasIds.map(areaId => {
                    const area = getEducationalAreaById(areaId)
                    return area ? <img src={area.icon} title={area.name} alt={area.name} /> : null
                })
                }
            </div>
        </>
    )
}

export default function EducationalActivityView(props: any) {

    const history = useHistory()
    const slug = props.match.params.slug
    const activity = activities.find(activity => activity.slug === slug)

    if (!activity) {
        return <div>Atividade Não Encontrada</div>
    }

    const handleClick = () => {
        history.push(`/educativo/programa-escolas/inscrever/${activity.slug}`)
    }
    return (
        <div>
            <GoBackToSchool />
            <EducationalHero category={activity.category} />
            <Grid container justify="center" className={styles.Wrapper}>
                <main>
                    <Grid container justify="space-between">
                        <Grid item xs={5}>
                            <ActivityName activity={activity} />

                        </Grid>
                        <Grid item xs={5}>
                        </Grid>

                        <Grid item xs={12} sm={5} className={styles.A}>

                            <div className={styles.DetailsAndAreasWrapper}>
                                <DetailsAndAreas activity={activity} />
                            </div>

                            <div style={{ marginBottom: 75, marginTop: 75 }}>
                                <BlackButton text="Reservar" onClick={handleClick} fullWidth />
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={5} className={styles.B}>
                            <div dangerouslySetInnerHTML={{ __html: activity.description }} className={styles.Description} />
                        </Grid>


                    </Grid>

                    <RelatedEducationalActivities activity={activity} />
                </main>
            </Grid>
        </div>
    )
}
