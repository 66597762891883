import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Helmet } from "react-helmet";
import social from '../../../assets/images/circus/2022/circo2022_social.jpg';
import { Config } from '../../../services/Config';
import CircusRow from '../CircusRow/CircusRow';
import CircusStar from '../CircusStar/CircusStar';
import artists from './Artists.json';
import styles from './Circus2022View.module.scss';
import photo1 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_01.jpg';
import photo2 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_02.jpg';
import photo3 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_03.jpg';
import photo4 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_04.jpg';
import photo5 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_05.jpg';
import photo6 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_06.jpg';
import photo7 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_07.jpg';
import photo8 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_08.jpg';
import photo9 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_09.jpg';
import photo10 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_10.jpg';
import photo11 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_11.jpg';
import photo12 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_12.jpg';
import photo13 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_13.jpg';
import photo14 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_14.jpg';
import photo15 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_15.jpg';
import photo16 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_16.jpg';
import photo17 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_17.jpg';
import photo18 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_18.jpg';
import photo19 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_19.jpg';
import photo20 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_20.jpg';
import photo21 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_21.jpg';
import photo22 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_22.jpg';
import photo23 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_23.jpg';
import photo24 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_24.jpg';
import photo25 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_25.jpg';
import photo26 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_26.jpg';
import photo27 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_27.jpg';
import photo28 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_28.jpg';
import photo29 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_29.jpg';
import photo30 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_30.jpg'
import photo31 from '../../../assets/images/circus/2022/photos/coliseu_porto_ageas_circo_2022_31.jpg'
import CircusPhotoGallery from '../CircusPhotoGallery/CircusPhotoGallery';

const photos = [
    photo1,
    photo2,
    photo3,
    photo4,
    photo5,
    photo6,
    photo7,
    photo8,
    photo9,
    photo10,
    photo11,
    photo12,
    photo13,
    photo14,
    photo15,
    photo16,
    photo17,
    photo18,
    photo19,
    photo20,
    photo21,
    photo22,
    photo23,
    photo24,
    photo25,
    photo26,
    photo27,
    photo28,
    photo29,
    photo30,
    photo31,
]


const Star = () => {
    return (
        <Grid container justify="center" className={styles.Star}>
            <CircusStar />
        </Grid>
    )
}


const CircusBuyButton = () => {
    const buyLink = "https://www.coliseu.pt/agenda"
    return (
        <Button className={styles.BuyLink} href={buyLink}>
            Compre já
        </Button>
    )
}

const Circus2022View = () => {

    return (
        <>
            <Helmet>
                <meta property="og:site_name" content="Coliseu Porto Ageas" />
                <meta property="og:url" content={`${Config.APP}circo-2022`} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Coliseu Porto Ageas" />
                <meta property="og:description" content="De 8 de dezembro a 8 de janeiro, o Circo de Natal do Coliseu Porto Ageas volta a reinventar-se com os melhores artistas para mostrar, diante dos nossos olhos, o que as artes circenses têm de mais fantástico." />
                <meta property="og:image" content={"https://www.coliseu.pt" + social} />
                <meta property="og:image:width" content="658" />
                <meta property="og:image:height" content="540" />
            </Helmet>

            <div className={styles.Circus2022}>
                <div className={styles.Hero} />

                <div className={styles.Intro}>

                    <div>
                        <h1>A grande Festa do Circo</h1>
                    </div>

                    <div className={styles.Row2}>
                        <h2>De 8 de dezembro a 8 de janeiro, o Circo de Natal do Coliseu Porto Ageas volta a reinventar-se com os melhores artistas para mostrar, diante dos nossos olhos, o que as artes circenses têm de mais fantástico.</h2>

                        <CircusBuyButton />

                    </div>

                    <Star />

                    <div className={styles.Row}>
                        <p>Ao nosso lado mais brincalhão junta-se a direção artística do clown Rui Paixão, a direção de ilusionismo do mágico Mário Daniel e um elenco de talentosos artistas circenses de diferentes nacionalidades, que apresentam números em estreia absoluta.</p>
                    </div>


                    <Star />

                    <div className={styles.Row}>

                        <p>

                            Pelo terceiro ano consecutivo, o Circo terá música tocada ao vivo em todas as sessões. Desafiámos o compositor Ramón Galarza a dirigir a banda sonora e a criar novos arranjos para o disco “A Festa do Circo”, por si gravado e editado há 35 anos para o Chapitô, com músicas de Sérgio Godinho, Nino Rota, Rão Kyao ou António Victorino d’Almeida.</p>
                    </div>


                    <Star />


                    <div className={styles.Row}>
                        <p>Num cenário de grande impacto visual, que faz lembrar um videojogo ou um deserto cyberpunk, e que podia ser a versão infantil de “Mad Max”, este ano o Circo inspira-se na fábula inacabada "Os Gigantes da Montanha", de Luigi Pirandello. Vamos imaginar com o público como teria sido o final desta história, que segue uma Companhia de circo em fuga da cidade, depois de vários espetáculos fracassados. Perdidos no meio do deserto, encontram um grupo de “azarentos” que ali vivem isolados, pois acreditam ser a única forma de fazer existir a sua arte e as suas crenças.</p>
                    </div>


                    <Star />

                    <div className={styles.Row}>
                        <p>Estando a Companhia sem destino e os “azarentos” curiosos, propõem-se celebrar em conjunto com música, dança, magia, fogo, malabarismo, clown, suspensão capilar, rolla bolla, pinos, diabolo, roda cyr e até uma roda da morte. Assim vai ser a Festa do Circo!</p>


                        <CircusBuyButton />
                    </div>


                </div>

                {artists.map(artist => <CircusRow key={Math.random()} {...artist} />)}


                <section className={styles.ArtisticSheet}>
                    <h2>Ficha Artística</h2>
                    <p>Direção e Coordenação Geral: Mónica Guerreiro</p>
                    <p>Direção Artística: Rui Paixão</p>
                    <p>Direção Musical: Ramón Galarza</p>
                    <p className={styles.Spacing}>Direção Ilusionismo: Mário Daniel</p>

                    <p>Cenografia, Adereços, Máscara e Marioneta: Cristóvão Neto</p>
                    <p>Design de Figurinos: Lola Sousa</p>
                    <p>Desenho de Luz: Manuel Abrantes</p>
                    <p>Sonoplastia: Bernardo Fesch e António Mão de Ferro</p>
                    <p className={styles.Spacing}>Coreografia Balleteatro: Rina Marques</p>

                    <p>Artistas: Alan Sencades, Carmo Madeira, Carolina Vasconcelos, Daniel Gonçalves, Dmitry Alexandrov, Emilia Parol, Mauricio Jara, Nico Pires, Paulo Azevedo, Pedro Matias, Priscila Fernandes, Rui Paixão e Trupe Peres</p>

                    <p>
                        Banda da Festa do Circo: Alexandre Baptista, Alexandre Gouveia, Carolina Frederico, Daniel Dias, David Sousa, Diogo Santos Diogo Santos, João Salcedo, Luís Oliveira, Miguel Bessa, Moisés Fernandes, Paulo Gravato, Rui Pedro Silva, Ryoko Imai, Tiago Afonso, Tiago Santos
		    </p>

                    <p className={styles.Spacing}>
                        Alunos Balleteatro: Ana Mendes, Beatriz Silva, Cristiana Neto, Diogo Gomes, Fabiana Pinto, Francisca Silva, Francisco Flores, Inês Neves, Luana Oliveira, Luna Castãneda, Sara Rocha, Susana Pires, Yohana Gonçalves, Beatriz Almeida, Carolina Pereira, Inês Silva, Inês Ferreira, Ivone Neves, Joana Mendonça, Lara Almeida, Lara Rodrigues, Maria Vieira, Matilde Araújo, Paulo Martins, Salomé Moreira e Tatiana Martins
		    </p>

                    <p>Construção Plástica dos Puffs & Rui Paixão: Nuno Encarnação e Lola Sousa</p>
                    <p>Assistência de Figurinos: Paula Cabral, Eloísa d`Ascensão e Mafalda Patrícia Costa</p>
                    <p>Assistência de Cenografia: Beatriz Prada, Bárbara Amen e Mariana Topa</p>
                    <p>Construção Adereços de Ator: Paula Cabral e Pedro Morim</p>
                    <p>Execução de Figurinos: Maria da Glória Costa, Lurdes Sobrado, Ana Maria Fernandes e Mafalda Patrícia Costa</p>
                    <p>Apoio a execução dos figurinos: Rita Eustáquio e Rúben Ponto</p>
                    <p>Caracterização: Ana Maria Simões e Maria Inês Campos</p>
                    <p>Máscaras: Susete Rebelo e Nuno Encarnação</p>
                    <p>Direção de cena: Graça Barreto</p>
                    <p>Assistentes de Direção de Cena: Diogo Moreira e Tiago Mota</p>
                </section>


                <section className={styles.TechnicalSheet}>
                    <h2>Ficha Técnica</h2>
                    <p>Direção: Mónica Guerreiro</p>
                    <p>Departamento de Produção e Programação: Fátima Pinto, Graça Barreto, Pedro Nascimento</p>
                    <p>Departamento Administrativo e Financeiro: José Carlos Pinto Coelho, Eduardo Cambra</p>
                    <p>Departamento Técnico: Luís Barros, Fernando Mota, João Pereira, José Cunha, Márcio Correia, Pedro Moreira, Tiago Castro</p>
                    <p>Bilheteira: Celestino Teixeira, Paulo Alves</p>
                    <p>Frente de Casa e Relações Públicas: Juliana Agostinho</p>
                    <p>Comunicação: Sara Coelho</p>
                    <p>Fotografia: Lara Jacinto</p>
                    <p>Assessoria: Cristina Aguiar</p>
                    <p className={styles.Spacing}>Expediente e Limpeza: Justina Vilela</p>
                    <p>
                        Este espetáculo homenageia o Chapitô e o seu pioneirismo na valorização das artes circenses em Portugal, interpretando na íntegra o álbum &nbsp;
			<a href="https://bsocirco.coliseu.pt/" target="_blank" rel="noopener noreferrer">"A Festa do Circo"</a> &nbsp;
			(1987).
		    </p>
                </section>

                <CircusPhotoGallery photos={photos} year={2022} />

            </div>
        </>
    );

};

export default Circus2022View;
