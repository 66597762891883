import React, { ReactNode } from "react"
import ArchiveView from "../ArchiveView/ArchiveView"
import AwardView from "../AwardView/AwardView"
import BalleteatroView from "../BalleteatroView/BalleteatroView"
import BookingInformationView from "../BookingInformationView/BookingInformationView"
import BuildingView from "../BuildingView/BuildingView"
import Circus2015View from "../CircusView/Circus2015View/Circus2015View"
import Circus2016View from "../CircusView/Circus2016View/Circus2016View"
import Circus2017View from "../CircusView/Circus2017View/Circus2017View"
import Circus2018View from "../CircusView/Circus2018View/Circus2018View"
import Circus2019View from "../CircusView/Circus2019View/Circus2019View"
import Circus2020View from "../CircusView/Circus2020View/Circus2020View"
import Circus2021View from "../CircusView/Circus2021View/Circus2021View"
import Circus2022View from "../CircusView/Circus2022View/Circus2022View"
import Circus2023View from "../CircusView/Circus2023View/Circus2023View"
import ContactsView from "../ContactsView/ContactsView"
import CookiesView from "../CookiesView/CookiesView"
import DirectionsView from "../DirectionsView/DirectionsView"
import EducationalInformationView from "../EducationalInformationView/EducationalInformationView"
import EducationalSectionView from "../EducationalSectionView/EducationalSectionView"
import EloView from "../EloView/EloView"
import HistoryView from "../HistoryView/HistoryView"
import ImageRightsView from "../ImageRightsView/ImageRightsView"
import NewsView from "../NewsView/NewsView"
import PaintingStudioView from "../PaintingStudioView/PaintingStudioView"
import PostDetailsView from "../PostDetailsView/PostDetailsView"
import PresentationView from "../PresentationView/PresentationView"
import PrivacyView from "../PrivacyView/PrivacyView"
import ReportsView from "../ReportsView/ReportsView"
import ResidencyView from "../ResidencyView/ResidencyView"
import RoomNormsView from "../RoomNormsView/RoomNormsView"
import ScheduleViewGrid from "../ScheduleViewGrid/ScheduleViewGrid"
import SchoolsView from "../SchoolsView/SchoolsView"
import SocialEntitiesView from "../SocialEntitiesView/SocialEntitiesView"
import SpacesView from "../SpacesView/SpacesView"
import StatutesView from "../StatutesView/StatutesView"
import SubscribeView from "../SubscribeView/SubscribeView"
import TechnicalAreaView from "../TechnicalAreaView/TechnicalAreaView"

export interface SubSection {
    id: number
    name: string
    route: string
    component?: ReactNode
    hide?: boolean
}

export interface Section {
    id: number
    name: string
    route: string
    hide?: boolean
    subsections?: SubSection[]
}

export const sections: Section[] = [
    {
        id: 1,
        name: "Agenda",
        route: "/agenda",
        subsections: [
            {
                id: 11,
                name: "Agenda",
                route: "/agenda",
                component: <ScheduleViewGrid />
            },
            {
                id: 12,
                name: "Arquivo",
                route: "/arquivo",
                component: <ArchiveView />
            },
            {
                id: 13,
                name: "Newsletter",
                route: "/newsletter/subscrever",
                component: <SubscribeView />
            }
        ]
    },
    {
        id: 2,
        name: "Bilheteira",
        route: "/informacoes-e-reservas",
        subsections: [
            {
                id: 21,
                name: "Informações e Reservas",
                route: "/informacoes-e-reservas",
                component: <BookingInformationView />
            },
            {
                id: 22,
                name: "Como Chegar",
                route: "/como-chegar",
                component: <DirectionsView />
            }
        ]
    },
    {
        id: 3,
        name: 'Instituição',
        route: '/apresentacao',
        subsections: [
            {
                id: 31,
                route: '/apresentacao',
                name: 'Apresentação',
                component: <PresentationView />
            },
            {
                id: 32,
                route: '/edificio',
                name: 'Edifício e Arquitetura',
                component: <BuildingView />
            },
            {
                id: 33,
                route: '/espacos',
                name: 'Espaços',
                component: <SpacesView />
            },
            {
                id: 34,
                route: '/historia',
                name: 'História',
                component: <HistoryView />
            },
            {
                id: 36,
                route: '/balleteatro',
                name: 'Balleteatro',
                component: <BalleteatroView />
            },
            {
                id: 37,
                route: '/premio',
                name: 'Prémio Jovens Artistas',
                component: <AwardView />
            },
            {
                id: 38,
                route: '/residencias',
                name: 'Residências Artísticas',
                component: <ResidencyView />
            },
            {
                id: 39,
                route: 'https://mantras.coliseu.pt/',
                name: 'Mantras do Coliseu',
            }
        ]
    },
    {
        id: 4,
        name: 'Serviço Educativo',
        route: '/educativo',
        subsections: [
            {
                id: 41,
                name: 'Serviço Educativo',
                route: '/educativo',
                component: <EducationalSectionView />
            },
            {
                id: 42,
                name: 'Programa escolas',
                route: '/educativo/programa-escolas',
                component: <SchoolsView />
            },
            {
                id: 43,
                name: 'Atelier Pintura',
                route: '/educativo/atelier-pintura',
                component: <PaintingStudioView />
            },
            {
                id: 44,
                name: 'ELO',
                route: '/educativo/elo',
                component: <EloView />

            },
            {
                id: 45,
                name: 'Informações',
                route: '/educativo/informacoes',
                component: <EducationalInformationView />
            }
        ]
    },
    {
        id: 5,
        name: 'Visitas Guiadas',
        route: '/visitas',
        subsections: [
            {
                id: 51,
                name: 'TBA',
                route: '/visitas'
            }
        ]
    },
    {
        id: 6,
        name: "Informações",
        route: '/arquivo-noticias',
        subsections: [
            {
                id: 61,
                route: '/arquivo-noticias',
                name: 'Notícias',
                component: <NewsView />,
            },
            {
                id: 62,
                route: '/normas-sala',
                name: 'Normas da Sala',
                component: <RoomNormsView />,
            },
            {
                id: 63,
                route: '/area-tecnica',
                name: 'Área Técnica',
                component: <TechnicalAreaView />,
            },
            {
                id: 64,
                route: '/relatorios',
                name: 'Relatórios',
                component: <ReportsView />,
            },
            {
                id: 65,
                route: '/orgaos-sociais',
                name: 'Órgãos Sociais',
                component: <SocialEntitiesView />,
            },
            {
                id: 66,
                route: '/contactos',
                name: 'Contactos',
                component: <ContactsView />,
            },
            {
                id: 67,
                route: '/noticia/:slug',
                name: 'Notícia',
                component: <PostDetailsView />,
                hide: true
            }
        ]
    },
    {
        id: 7,
        name: 'Amigos Coliseu',
        route: 'https://amigos.coliseu.pt',
        subsections: [
            {
                id: 71,
                name: 'Aderir',
                route: 'https://amigos.coliseu.pt/#subscription-form'
            },
            {
                id: 72,
                name: 'Vantagens',
                route: 'https://amigos.coliseu.pt/#vantagens',
            },
            {
                id: 73,
                name: 'Estatutos',
                route: '/estatutos',
                component: <StatutesView />
            },
            {
                id: 74,
                name: 'Entrar',
                route: 'https://amigos.coliseu.pt/#login'
            }
        ]
    },
    {
        id: 8,
        name: 'Circo',
        route: '/circo-2023',
        subsections: [
            {
                id: 81,
                route: '/circo-2023',
                name: '2023',
                component: <Circus2023View />
            },
            {
                id: 82,
                route: 'https://bsocirco.coliseu.pt/',
                name: 'Bandas Sonoras',
            },
            {
                id: 83,
                route: '/circo-2022',
                name: '2022',
                component: <Circus2022View />
            },
            {
                id: 84,
                route: '/circo-2021',
                name: '2021',
                component: <Circus2021View />
            },
            {
                id: 85,
                route: '/circo-2020',
                name: '2020',
                component: <Circus2020View />
            },
            {
                id: 86,
                route: '/circo-2019',
                name: '2019',
                component: <Circus2019View />
            },
            {
                id: 87,
                route: '/circo-2018',
                name: '2018',
                component: <Circus2018View />
            },
            {
                id: 88,
                route: 'https://circo.coliseu.pt/',
                name: 'FICPorto 2018',
            },
            {
                id: 89,
                route: '/circo-2017',
                name: '2017',
                component: <Circus2017View />
            },
            {
                id: 891,
                route: '/circo-2016',
                name: '2016',
                component: <Circus2016View />
            },
            {
                id: 892,
                route: '/circo-2015',
                name: '2015',
                component: <Circus2015View />
            },
        ]
    },
    {
        id: 9,
        name: 'Politicas',
        route: '/politica-de-privacidade',
        hide: true,
        subsections: [
            {
                id: 91,
                route: '/politica-de-privacidade',
                name: 'Política Privacidade',
                component: <PrivacyView />
            },
            {
                id: 92,
                route: '/cookies',
                name: 'Política Cookies',
                component: <CookiesView />
            },
            {
                id: 93,
                route: '/direito-a-imagem',
                name: 'Direito Imagem',
                component: <ImageRightsView />
            }
        ]
    }
]
